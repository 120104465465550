.system-of-payment {
  padding: 24px;
}

.how-to-pay__section-offset {
  padding-bottom: 24px;
}

.bold {
  font-family: "Museo Sans Cyrl 500";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.how-to-pay__container {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  max-width: 800px;
  border-radius: 24px;
  background: $white;
  box-shadow: 0 1px 4px 0 $smoke_white_shadow, 0 24px 48px 0 $grey_shadow;
}

.system_of_payment__descr-block {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.system-of-payment__header {
  font-family: "Museo Sans Cyrl 500";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  color: $black;
}

.system-of-payment__descr {
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

.system-of-payment__agency-descr {
  display: flex;
  padding: 16px 24px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid rgba(255, 118, 0, 0.50);
}

.system-of-payment__agency {
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}
