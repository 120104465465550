.booking-conditions__section {
  padding-top: 24px;
  box-shadow: 0 1px 4px 0 $box-shadow-tabs  , 0 24px 48px 0 $box-sh-tabs;
}

.booking-conditions__block {
  margin: 0 auto;
  max-width: 800px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 24px;
  background-color: $white;
}

.booking-conditions-table__block {
  outline: 1px solid $inactive-icon;
  border-radius: 24px;
}

.booking-conditions-table__block>table {
  width: 100%;
}

.booking-conditions__table {
  margin-bottom: 0;
}


.table__header {
  padding: 16px 24px;
  font-family: "Museo Sans Cyrl 500";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: $black;
}

.booking-conditions__header {

}

.booking-conditions__table > th, td {
  padding: 16px 24px ;
}

@media (max-width: 768px) {
  .booking-conditions__section {
    padding: 20px 16px 48px 16px;
  }

  .booking-conditions__table > th , td {
    padding: 16px 12px;
  }

  .table__header {
    padding: 16px 11px;
  }

}
