.about-company {
  padding-top: 24px;
  padding-bottom: 64px;
}

.about-company__header {
  font-family: "Museo Sans Cyrl 500";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
}

.about-company__descr-block {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.about-company__descr {
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}
