@import "base/variables";
@import "base/all";
@import "base/normalise";
@import "base/sidebars-common.scss";

@import "components/banner";
@import "components/form";
@import "components/best-advices";
@import "components/golden-ring";
@import "components/about-us";
@import "components/awards";
@import "components/feedback";
@import "components/news";

@import "components/system_of_payment";
@import "components/sbp";
@import "components/payment-bank-card";
@import "components/reject-tour";

@import "components/agency-contacts";
@import "components/go-back";
@import "components/my-tour-vladimir";
@import "components/my-tour-moscow";
@import "components/my-tour-spb";

@import "components/catalogue-current-year";
@import "components/important-info";
@import "components/departure-groups-info";
@import "components/important-info-feedback";
@import "components/about-company";

@import "components/header";
@import "components/login";
@import "components/agency_registration";
@import "components/individual_registration";
@import "components/footer";
@import "components/header_index";
@import "components/individual_account";
@import "components/agency_account";
@import "components/account_sidebar";
@import "components/swiper";
@import "components/tour-request";
@import "components/gallery-slider";
@import "components/tour-details";
@import "components/reservation";

@import "components/all-tours";
@import "components/bank-details";
@import "components/fin-guarantees";
@import "components/partners-info.scss";
@import "components/contract";
@import "components/schedule-of-arrival";
@import "components/discounts";
@import "components/placing-tours";
@import "components/register-and-insurance";
@import "components/faq";
@import "components/booking-conditions";
