.form-banner {
  justify-content: space-between;
  background: $white;
  z-index: 100;
  align-items: center;
  border-radius: 16px 16px 0 0;
}

.js-choice {
  z-index: 100;
}

.form-banner > .choices__inner {
  border: 2px solid $button-color;
}

.form-banner > .first > .choices,
.form-banner > .second > .choices {
  width: 100%;
  z-index: 100;
}

.form-banner>.first>.choices>.choices__inner,
.form-banner>.second>.choices>.choices__inner{
  position: relative;
  height: 72px;
  width: 100%;
  padding: 22px 12px 12px 22px;
  background-color: $white;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $black;
  border: 2px solid $white;
  border-radius: 16px;
  transition: background-color .3s ease;
  z-index: 100;

  &:hover {
    background-color: $button-hover;
    border-color: $button-hover;
  }
}

.form-banner>.first>.is-focused.choices>.choices__inner,
.form-banner>.second>.is-focused.choices>.choices__inner{
  background-color: $button-hover;
  border-color: $button-color;
}

.form-banner>.first>.is-focused.choices>.choices__inner,
.form-banner>.first>.is-open.choices>.choices__inner,
.form-banner>.second>.is-focused.choices>.choices__inner,
.form-banner>.second>.is-open.choices>.choices__inner {
  border: 2px solid $button-color;
  border-radius: 16px;
}

.form-banner > .first > .choices > .choices__list--dropdown .choices__list,
.form-banner > .second > .choices > .choices__list--dropdown .choices__list {
  max-height: unset;
}

.date-input {
  position: relative;
  width: 285px;
  height: 72px;
  padding: 24px;
  cursor: pointer;
  color: $black;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  border: 2px solid $white;
  border-radius: 12px;
  transition: background-color ease .3s;
  background: url('../../img/calendar.svg');
  background-repeat: no-repeat;
  background-position: 237px ;
  z-index: 100;

  &:focus-within {
    outline: none;
  }

  &:hover {
    background-color: $button-hover;
    border-color: $button-hover;
  }

  &:focus {
    background-color: $button-hover;
    border: 2px solid $button-color;
    border-radius: 12px;
  }

  &:active {
    border: 2px solid $button-color;
  }
}

.first {
  width: 435px;
}

.second {
  width: 420px;
}

.first,
.second {
  display: flex;
  transition: background-color .3s ease;
}

.choices[data-type*=select-one] {
  z-index: 100;
  margin-bottom: 0;
}

.choices.is-focused.is-open {
  z-index: 500;
}

.choices.is-focused.is-open.is-flipped {
  //z-index: 500;
}

.is-focused .choices__list--dropdown,
.is-active .choices__list--dropdown {
  border-color: $button-color;
  border: 2px solid $button-color;
  border-radius: 12px;
  box-shadow: 1px 3px 5px 0 rgba(223, 231, 248, 0.79);
}

.choices__list--dropdown .choices__item {
  padding: 12px 16px;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

.choices__list--dropdown .choices__item--selectable {
  background-color: $white;
}

.choices[data-type*=select-one]:after {
  height: 10px;
  width: 10px;
  z-index: 100;
  top: 40%;
  right: 24px;
  border: 2px solid $button-color;
  border-width: 0 0 2px 2px;
  transform: rotate(-45deg);
}

.choices[data-type*=select-one].is-open:after {
  top: 60%;
  border: 2px solid $button-color;
  border-width: 2px 2px 0 0;
}

.is-flipped.is-open .choices__inner {
  border-radius: 12px;
}

.choices__list--dropdown {
  top: calc(100% - 30px);
  padding-top: 30px;
  box-shadow: 1px 3px 5px 0 rgba(223, 231, 248, 0.79);
}

.choices__placeholder {
  opacity: 1;
}

.choices__list--dropdown .choices__placeholder {
  display: none;
}

.choices__item .choices__item--selectable {
  padding-right: 24px;
}

.choices__list--dropdown .choices__list {
  z-index: 100;
  overflow: unset;
}

.is-open .choices__list--dropdown {
  z-index: 99;
}

.choices__list--dropdown .choices__item--selectable {
  transition: background-color .3s ease;
}

.choices__list--dropdown .choices__item--selectable.is-highlighted {
  background-color: $button-hover;
}

.choices__item {
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

.choices__list--single {
  padding: 0;
}

.second>.choices>.is-active.choices__inner {
  border-radius: 12px;
}

input::-webkit-calendar-picker-indicator {
  opacity: 0;
  pointer-events: none;
}

.index__body > .air-datepicker-global-container {
  width: 282px !important;
}

.index__body > .air-datepicker-global-container > .air-datepicker.-bottom-left-.-from-bottom-.-active- {
  width: 100% !important;
}


.air-datepicker--pointer {
  display: none !important;
}

.air-datepicker {
  margin-top: -24px !important;
  padding-top: 12px !important;
  width: 281px !important;
  box-shadow: 1px 3px 5px 0 rgba(223, 231, 248, 0.79) !important;
  background-color: $white !important;
  border-radius: 12px !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border: 2px solid $button-color !important;
  z-index: 2 !important;
}

.air-datepicker--navigation {
  font-family: "Museo Sans Cyrl 500" !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 24px !important;
}

.air-datepicker-nav--action path {
  stroke: $button-color !important;
}

.air-datepicker.-inline- {
  left: 50% !important;
  position: absolute !important;
}

.air-datepicker--navigation {
  padding: 16px 16px 0 16px !important;
}

.air-datepicker-nav {
  justify-content: center !important;
  border-bottom: none !important;
  z-index: 100 !important;
}

.air-datepicker-nav--title {
  width: 205px !important;
}

.air-datepicker-nav--action {
  width: 24px !important;
  height: 24px !important;
}

.air-datepicker-nav {
  padding: 0 !important;
}

.air-datepicker-cell.-selected- {
  color: $white !important;
  background: $button-color !important;
}

.air-datepicker--content {
  padding: 16px 8px !important;
}

.air-datepicker-body--day-name {
  font-family: "Museo Sans Cyrl 300" !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: 24px !important;
  color: #8e8888 !important;
  width: 34px !important;
  height: 24px !important;
  text-transform: lowercase !important;
}

.air-datepicker-cell.-current- {
  color: #000 !important;
}

.air-datepicker-cell {
  transition: color .3s ease, background .3s ease;
}

.air-datepicker-cell:hover {
  color: $button-color !important;
  background: $button-hover !important;
}

.air-datepicker-body--day-names {
  margin: 0 !important;
}

.air-datepicker-cell.-year-.-other-decade-:empty,
.air-datepicker-cell.-day-.-other-month-:empty:hover {
  background: none !important;
  pointer-events: none !important;
}

.btn:focus-within {
  outline: none;
}

.air-datepicker {
  transition: none !important;
}

.additional-display {
  display: none;
  box-sizing: content-box;
  left: 859px;
  top: 55px;
  z-index: 99 !important;
  background-color: #fff !important;
  position: absolute;
  width: 282px;
  height: 25px;
  border-bottom: 0 !important;
  border-top: 0 !important;
  border-left: 2px solid $button-color;
  border-right: 2px solid $button-color;
  border-radius: 0 !important;
}

.show-calendar {
  display: block;
}

.form-banner__submit-text {
  display: none;
}

@media screen and (max-width: 768px) {
  .form-banner {
    flex-direction: column;
    border-radius: 0;
  }

  .svg-split-form {
    display: none;
  }

  .choices__inner {
    width: 100%;
  }

  .choices[data-type*=select-one]:after {
    top: 41%;
    right: 30px;
  }

  .first {
    width: 320px;
    border-bottom: 2px solid $input-spliter;
  }

  .second {
    width: 320px;
    border-bottom: 2px solid $input-spliter;
  }

  .date-input {
    width: 100%;
    background-position: 272px
  }

  .show-calendar {
    display: none;
  }

  .additional-display {
    left: 0;
    top: 263px;
    width: 316px;
  }

  .form-banner__submit-text {
    display: block;
    color: $white;
    font-weight: 600;
    line-height: 24px;
  }

  .air-datepicker.-bottom-left-.-from-bottom-.-active- {
    width: 316px !important;
    top: 690px !important;
    z-index: 99 !important;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
  }
}
