.faq__section {
  padding-top: 24px;
}

.faq__container {
  margin: 0 auto;
}

.faq-block__header {
  max-width: 800px;
  margin: 24px auto;
}

.ui-accordion .ui-accordion-header {
  margin: 0 !important;
  padding: 24px !important;
  background-color: $white !important;
  border-radius: 24px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.accordion-item {
  width: 100%;
    box-shadow: 0 1px 4px 0 $box-shadow-tabs , 0 24px 48px 0 $box-sh-tabs !important;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
  background: $white !important;
  border: none !important;
}

.ui-icon,
.ui-widget-content .ui-icon {
  display: none !important;
}

.ui-accordion .ui-accordion-content {
  display: block;
  z-index: 100;
  position: relative;
  top: -20px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  padding: 24px !important;
}

.ui-accordion-header-active {
  transition: rotate .3s ease;
}

.ui-accordion-header-active>svg {
  transform: rotate(180deg);
}

.accordion {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.ui-widget {
  margin: 0 auto !important;
}

.accordion-descr {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: none !important;
}

.faq__header {
  padding: 24px;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: $white;
  border-radius: 24px;
  margin: 0 auto;
  margin-bottom: 24px;
}

.accordion-link-page {
  color: $button-color !important;
  font-family: "Museo Sans Cyrl 500" !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 24px !important;
}

.faq__question {

}

.faq__question > span {
  max-width: 699px;
}

.accordion-unordered-list {
  display: list-item;
  list-style: disc;
}

.unordered-list__item-accordion {
  list-style: disc;
}

.unordered-list__item-accordion::marker {
  color: $button-color;
  width: 8px;
  height: 16px;
}

@media (max-width: 768px) {
  .faq__section {
    padding: 20px 16px 48px 16px;
  }

  .faq__header {
    max-width: 288px;
  }

  .accordion {
    max-width: 288px !important;
  }

  .faq__question > span {
    width: 208px !important;
  }

  .ui-accordion .ui-accordion-header {
    align-items: flex-start !important;
  }
}
