.tour-request {
  width: auto;
  padding-bottom: 24px;
}

.tour-request__container {
  width: 800px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0;
}

.tour-request:last-of-type {
  padding-bottom: 64px;
}

.tour-request__button-back-arrow {
  width: 24px;
  height: 24px;
}

.tour-request__button-back-text {
  font-family: "Museo Sans Cyrl 500";
  font-weight: 600;
  color: $button-color;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
}

.tour-request__content-container {
  width: 800px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.tour-request__button-back {
  width: 83px;
  height: 24px;
  line-height: 24px;
  display: flex;
  gap: 12px;
  margin: 24px 0;
  align-items: center;
}

.tour-request__content-card {
  background-color: $white;
  box-shadow: 0 1px 4px 0 $box-shadow-tabs, 0 24px 48px 0 $box-sh-tabs;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
}

.picture-card {
  height: 100%;
}

.tour-request__content-input-card {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.tour-request__card-name {
  font-family: "Museo Sans Cyrl 700";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

.tour-request__dropdown {
  display: flex;
  justify-content: space-between;
  height: 80px;
  padding: 24px;
  border-top: 1px solid var(--border-color, $bottom-line);
  background: var(--background-color, $white);
  cursor: pointer;
}

.hotels-card {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.dropdown-card {
  height: 80px;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.gallery-card {
  height: 436px;
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.feedback-card-container {
  padding: 24px;
}

.feedback-card-container-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
}

.tour-request__content-card-pic {
  width: 800px;
  height: 304px;
  padding: 4px 4px 0 4px;
}

.pic-tour-request {
  border-radius: 24px;
}

.tour-request__tour-name {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.tour-request__tour-name-text {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.tour-request__tour-name-btn {
  display: flex;
  width: 241px;
  height: 44px;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--border-color, $button-color);
  background: var(--background-color, $white);
  color: $button-color;
  font-family: "Museo Sans Cyrl 500";
  font-weight: 600;
  cursor: pointer;
}

.tour-request__tour-name-btn:hover {
  background: var(--background-color, $button-hover);
  transition: background-color 0.3s ease;
}

.tour-request__tour-name-text-main {
  font-family: "Museo Sans Cyrl 700";
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
}

.tour-name-dropdown {
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  cursor: pointer;
}

.tour-request__input-container {
  position: relative;
  background-color: $input-color;
  width: 752px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tour-request__first-input,
.tour-request__second-input {
  position: relative;
  width: 375px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 98;
}

.svg-icon-input {
  position: absolute;
}

.common-text-name {
  font-family: "Museo Sans Cyrl 700";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.tour-request__checkbox-container {
  height: 28px;
  padding: 2px;
}

.tour-request__checkbox-text {
  height: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

.tour-request__line {
  display: flex;
  justify-content: center;
  height: 0;
}

.input-big {
  height: 72px;
  padding: 24px;
  justify-content: flex-start;
}

.checkbox-wrapper-30 {
  --size: 25px;
  position: relative;
  width: 423px;
  height: 28px;
}

.checkbox-wrapper-30 *,
.checkbox-wrapper-30 *:before,
.checkbox-wrapper-30 *:after {
  box-sizing: border-box;
}

.checkbox-wrapper-30 .promoted-input-checkbox {
  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.checkbox-wrapper-30 input:checked ~ svg {
  height: calc(var(--size) * 0.6);
  -webkit-animation: draw-checkbox-28 ease-in-out 0.2s forwards;
  animation: draw-checkbox-28 ease-in-out 0.2s forwards;
}

.checkbox-wrapper-30 label {
  color: $black;
  line-height: var(--size);
  cursor: pointer;
  position: relative;
}

.checkbox-wrapper-30 label:before {
  content: "";
  height: 24px;
  width: 24px;
  margin-right: 14px;
  padding: 2px;
  float: left;
  border: 2px solid $button-color;
  border-radius: 4px;
  transition: 0.15s all ease-out;
}

.checkbox-wrapper-30 svg {
  stroke: $button-color;
  stroke-width: 3px;
  height: 0;
  width: calc(var(--size) * 0.6);
  position: absolute;
  left: calc(var(--size) * 0.21);
  top: calc(var(--size) * 0.2);
  stroke-dasharray: 33;
}

.tour-request__input-card-btn {
  width: 178px;
  height: 44px;
  border-radius: 8px;
  padding: 10px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  font-family: "Museo Sans Cyrl 700";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  border: none;
  background-color: $button-color;
  cursor: pointer;
}

.tour-request__dropdown-text {
  font-family: "Museo Sans Cyrl 700";
  display: flex;
  align-items: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  overflow-wrap: break-word;
}

.dropdown-bottom {
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
}

// TODO: fix bottom padding mobile booking-form tour-request

.tour-request__hotels-cards {
  display: flex;
  justify-content: space-between;
}

.tour-request__hotel-card {
  width: 364px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.tour-request__hotel-pic {
  display: flex;
  gap: 16px;
}

.tour-request__hotel-image-container {
  border-radius: 9.33px;
  width: 56px;
  height: 56px;
}

.tour-request__hotel-image {
  border-radius: 9.33px;
  width: 100%;
  height: 100%;
}

.tour-request__hotel-text {
  width: 292px;
}

.tour-request__hotel-info {
  width: 364px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.tour-request__hotel-info-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.tour-request__link-container {
  width: 67px;
}

.tour-request__feedback-people {
  display: flex;
  gap: 24px;
}

.tour-request__feedback-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 4px solid $button-hover;
  border-radius: 24px;
  gap: 24px;
  padding: 20px;
}

.tour-request__account-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  width: 236px;
  line-height: 24px;
}

// fixme: add class to inputs and remove this code
.phone-input,
.passport-input,
.email-input,
.note-input,
.tour-input-form {
  height: 72px;
  padding: 30px 24px 8px;
  width: 375px;
  background-color: $input-color;
  border: none;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  border-radius: 12px;
  transition: background-color ease 0.3s;
  cursor: pointer;

  &:hover {
    background-color: $button-hover;
  }

  &:focus {
    background-color: $button-hover;
    border: 2px solid $button-color;
  }

  &:focus-within {
    outline: none;
  }
}

.label-for-input {
  position: absolute;
  left: 26px;
  top: 12px;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $grey;
  z-index: 102;
}

.first-input,
.second-input {
  position: relative;
  height: 100%;
}

.tour-request__first-input-holder {
  position: relative;
  height: 100%;
}

.tour-request__second-input-holder > .choices > .choices__inner,
.tour-request__first-input-holder > .choices > .choices__inner {
  position: relative;
  width: 375px;
  padding-top: 32px;
  border-radius: 12px;
  background-color: $input-color;
  border: 2px solid $input-color;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  transition: background-color 0.3s ease;
  z-index: 100;

  &:hover {
    background-color: $button-hover;
  }
}

.tour-request__second-input-holder > .is-focused.choices > .choices__inner,
.tour-request__first-input-holder > .is-focused.choices > .choices__inner {
  border-color: $button-color;
  background-color: $button-hover;
}

.tour-request__second-input-holder > .is-open.choices > .choices__inner,
.tour-request__first-input-holder > .is-open.choices > .choices__inner {
  border-color: $button-color;
  background-color: $button-hover;
}

.tour-request__second-input-select > .tour-request__second-input-holder > .choices::before {
  position: absolute;
  content: "Состав группы";
  left: 24px;
  top: 12px;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $gray;
  background-color: var(--primary-light);
  z-index: 112;
}

.tour-request__second-input > .tour-request__second-input-holder > .choices::before {
  position: absolute;
  content: "Транспорт";
  left: 22px;
  top: 12px;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $gray;
  background-color: var(--primary-light);
  z-index: 112;
}

.tour-request__first-input-eat > .tour-request__first-input-holder > .choices::before {
  position: absolute;
  content: "Питание";
  left: 24px;
  top: 12px;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $gray;
  background-color: var(--primary-light);
  z-index: 112;
}

.tour-request__second-input-holder > .choices > .choices__inner > .choices__list > .is-active {
  z-index: 110;
}

.tour-request__second-input-holder
  > .choices
  > .choices__inner
  > .choices__list
  > .choices__list--single
  .choices__item {
  padding-left: 24px;
}

.phone-input,
.email-input,
.fio-input,
.passport-input {
  width: 100%;
  height: 72px;
  padding: 24px;
  background-color: $input-color;
  border: 2px solid $input-color;
  border-radius: 12px;
  transition: background-color 0.3s ease;

  &:focus-within {
    outline: none;
  }

  &:hover {
    background-color: $button-hover;
  }
}

.phone-input,
.email-input,
.number-of-attendant,
.number-of-tourists,
.number-of-programm,
.fio-input,
.note-input,
.passport-input {
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

.tour-request__input-container svg {
  z-index: 101;
}

.note-input {
  height: 72px;
  padding: 24px;
  width: 100%;
  border-radius: 12px;
  border: 2px solid $input-color;
  background-color: $input-color;

  &:focus-within {
    outline: none;
  }
}

.choices__list--single .choices__item {
  max-width: 303px;
  padding-top: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.arrange-tour-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.choices__inner {
  padding: 35px 35px 35px 22px;
}

.hidden {
  display: none !important;
}

.tour-request__field {
  position: relative;
}

.tour-cost-descr-name {
  width: 630px;
}

.tour-request__dropdown-hidden {
  overflow: hidden;
  width: 800px;
  height: 0;
  padding: 0 24px;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
}

.tour-request__dropdown-hidden-card {
  overflow: hidden;
  width: 800px;
  height: 0;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
}

.tour-cost-hidden.show {
  height: 924px;
  padding: 0 24px 24px;
}

.about-cost-hidden.show {
  height: 96px;
  padding: 0 24px 24px;
}

.additional-order-hidden.show {
  height: 72px;
  padding: 0 24px 24px;
}

.dropdown-bottom-hidden.show {
  height: 108px;
  padding: 0 24px 24px;
}

.dropdown-card-hidden.show {
  height: 1548px;
}

.dropdown-bottom-hidden {
  gap: 12px;
}

.tour-cost-hidden {
  gap: 24px;
}

.tour-cost-descr {
  width: 752px;
  padding: 15px 23px;
  border-radius: 16px;
  border: 1px solid rgba(255, 118, 0, 0.5);
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
}

.tour-cost-table {
  width: 752px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.tour-cost-table-content {
  width: 752px;
  height: 674px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--border-color, $inactive-icon);
}

.tour-cost-table-thead {
  width: 752px;
  height: 112px;
  background: var(--background-color, rgba(255, 228, 204, 0.2));
}

.tour-cost-table-transport-right {
  border-top-right-radius: 24px;
}

.tour-cost-table-head {
  width: 170px;
  height: 112px;
  border-top-left-radius: 24px;
}

.tour-cost-table-transport {
  width: 291px;
  height: 56px;
}

.tour-cost-table-people {
  width: 145.5px;
}

.tour-cost-table-row {
  height: 56px;
}

.tour-request__dropdown-hidden-name {
  height: 80px;
  border-top: 1px solid var(--border-color, $inactive-icon);
  padding: 24px;
}

.tour-request__day-descr {
  padding: 0 24px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.tour-request__day1-descr-item-col {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.tour-request__day1-descr-item {
  display: flex;
  gap: 12px;
}

.tour-name-dropdown-hidden.show {
  height: 240px;
  padding: 4px;
  border-radius: 36px;
}

@media screen and (max-width: 768px) {

  .tour-request__container {
    max-width: 288px;
  }

  .tour-request__button-back {
    margin-top: 20px;
  }

  .tour-request__content-container {
    width: 100%;
  }

  .hotels-card {
    height: 100%;
    gap: 0;
  }

  .feedback-card-container-name {
    flex-direction: column;
    gap: 0;
    align-items: start;
  }

  .tour-request__content-input-card {
    height: 100%;
  }

  .tour-request__dropdown-hidden {
    transition: none;
  }

  .tour-request__content-card-pic {
    width: 290px;
    height: 300px;
  }

  .tour-cost-hidden.show {
    width: 100%;
    height: 100%;
  }

  .tour-cost-descr {
    width: 100%;
    height: 100%;
  }

  .tour-cost-descr-name {
    width: 100%;
    height: 100%;
  }

  .tour-cost-table {
    width: 100%;
    height: 100%;
  }

  .common-text-name {
    width: 100%;
    height: 100%;
  }

  .tour-cost-table-content {
    height: 100%;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .tour-cost-table-main {
    margin-bottom: 0;
  }

  .tour-request__tour-name {
    gap: 24px;
  }

  .show-conditions {
    width: 100%;
    height: 100%;
  }

  .about-cost-hidden.show {
    width: 100%;
    height: 100%;
  }

  .additional-order-hidden.show {
    width: 100%;
    height: 100%;
  }

  .dropdown-bottom-hidden.show {
    width: 100%;
    height: 100%;
  }

  .tour-request__tour-name-btn {
    line-height: 24px;
  }

  .picture-card {
    height: 100%;
  }

  .gallery-card {
    gap: 0;
  }

  .tour-request__gallery-content {
    width: 240px;
  }

  .arrange-tour-form {
    gap: 0;
  }

  .tour-request__hotels-cards {
    flex-direction: column;
    gap: 32px;
  }

  .dropdown-card-hidden.show {
    width: 100%;
    height: 100%;
  }

  .tour-request__dropdown-hidden-card {
    transition: none;
  }

  .tour-request__day-descr {
    height: 100%;
  }

  .tour-request__tour-name {
    height: 100%;
  }

  .tour-request__hotel-card {
    width: 100%;
    height: 100%;
  }

  .tour-request__hotel-image {
    width: 56px;
    height: 56px;
  }

  .tour-request__hotel-text {
    height: 100%;
  }

  .tour-request__first-input-holder {
    width: 100%;
  }

  .tour-request__hotel-info {
    width: 100%;
    height: 100%;
  }

  .label-for-input {
    max-width: 192px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .tour-request__first-input,
  .tour-request__second-input {
    width: 100%;
    margin-bottom: 16px;
    border-radius: 16px;
  }
  .tour-request__second-input-select {
    width: 100%;
  }

  .tour-request__input-container {
    flex-direction: column;
    background: transparent;
    width: 100%;
    height: 100%;
    gap: 0;
  }

  .tour-request__checkbox-container {
    height: 100%;
    padding: 0;
  }

  .tour-request__input-card-btn {
    width: 100%;
    margin-top: 16px;
  }

  .tour-request__line {
    margin: 24px auto;
    width: 100%;
  }

  .tour-input-form {
    width: 100%;
    min-width: 240px;
    margin-bottom: 16px;
  }

  .svg-icon-input {
    display: none;
  }

  .tour-request__first-input-eat {
    margin-bottom: 16px;
  }

  .tour-request__second-input-select {
    margin-bottom: 16px;
  }

  .tour-request__second-input-holder > .choices > .choices__inner,
  .tour-request__first-input-holder > .choices > .choices__inner {
    width: 100%;
    min-width: 240px;
  }

  .choices__list--single .choices__item {
    max-width: 172px;
  }

  .phone-input,
  .email-input,
  .note-input,
  .tour-input-form {
    border-radius: 16px;
  }

  .feedback-card-container {
    height: 100%;
  }

  .tour-request__feedback-card {
    height: 100%;
    max-height: unset;
  }

  .tour-request__feedback-people {
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    gap: 8px;
  }
}
