.agency {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 64px;
  padding-top: 40px;
}

.agency__container {
  max-width: 800px;
  margin: 0 auto;
}

.agency__content-form{
  background-color: $white;
  border-radius: 24px;
  padding: 24px;
  box-shadow: 0 1px 4px 0 $box-shadow-tabs, 0 24px 48px 0 $box-sh-tabs;
  font-weight: 600;
}

.agency__bottom-content {
  width: 752px;
}

h1.agency__title {
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 32px;
  font-style: normal;
  line-height: 40px;
}

.agency__contact-info {
  margin-bottom: 24px;
}

.agency__contact-info__last {
  margin-bottom: 32px;
}

.agency__input-group,
.agency__input-group-passwords {
  position: relative;
  width: 100%;
  height: 72px;
  display: flex;
  background-color: $input-color;
  border-radius: 16px;
}

.agency__input-group-passwords {
  margin-bottom: 8px;
}

.agency__input-group input,
.agency__input-group-passwords input {
  flex: 1;
  padding: 24px;
  background-color: $input-color;
  color: $black;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  border-radius: 16px;
  transition: background-color .3s ease;
  cursor: pointer;

  &:hover {
    background-color: $button-hover;
    border-color: $button-hover;
  }

  &:focus {
    background-color: $button-hover;
    border-color: $button-color;
  }
}

.agency__input-left,
.agency__input-right {
  border: 2px solid $input-color;
  border-radius: 16px;
  background-color: $input-color;
  transition: background-color .3s ease;
  cursor: pointer;

  &:hover {
    background-color: $button-hover;
    border-color: $button-hover;
  }

  &:focus {
    background-color: $button-hover;
    border-color: $button-color;
  }
}

.agency__password {
  width: 752px;
  height: 104px;
  margin-bottom: 32px;
}

.svg-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 150;
}

.agency__input-group input:focus,
.agency__input-group-passwords input:focus {
  outline: none;
}

.checkbox-wrapper-28 {
  --size: 25px;
  position: relative;
  height: 28px;
  padding-top: 2px;
}

.checkbox-wrapper-28 *,
.checkbox-wrapper-28 *:before,
.checkbox-wrapper-28 *:after {
  box-sizing: border-box;
}

.checkbox-wrapper-28 .promoted-input-checkbox {
  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.checkbox-wrapper-28 input:checked ~ svg {
  height: calc(var(--size) * 0.6);
  -webkit-animation: draw-checkbox-28 ease-in-out 0.2s forwards;
  animation: draw-checkbox-28 ease-in-out 0.2s forwards;
}

.checkbox-wrapper-28 label {
  color: $black;
  line-height: var(--size);
  cursor: pointer;
  position: relative;
}

.checkbox-wrapper-28 label:before {
  content: "";
  height: 24px;
  width: 24px;
  margin-right: 14px;
  padding: 2px;
  float: left;
  border: 2px solid $button-color;
  border-radius: 4px;
  transition: 0.15s all ease-out;
}

.checkbox-wrapper-28 svg {
  stroke: $button-color;
  stroke-width: 3px;
  height: 0;
  width: calc(var(--size) * 0.6);
  position: absolute;
  left: calc(var(--size) * 0.21);
  top: calc(var(--size) * 0.2);
  stroke-dasharray: 33;
  margin-top: 1px;
}

.agency-data {
  color: $button-color;
}

.agency__policy {
  width: 398px;
  height: 28px;
  margin-bottom: 16px;
}

.agency__bottom-symbols {
  color: $gray;
  line-height: 24px;
}

.agency__buttons {
  display: flex;
}

.entry-button,
.exit-button {
  display: flex;
  padding: 10px 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
}

.entry-button {
  width: 164px;
  height: 44px;
  border-radius: 8px;
  background: var(--entry-background, $button-color);
  color: $white;
  border: none;
  margin-right: 16px;
  line-height: 24px;
  transition: background-color ease .3s;
}

.entry-button:hover {
  background-color: $button-hover-color;
}

.entry-button:focus {
  outline: none;
}

.entry-button:active {
  background: var(--entry-hover-background, $button-focus);
}

.exit-button {
  width: 130px;
  height: 44px;
  border: 1px solid $button-color;
  border-radius: 8px;
  background: $white;
  color: $button-color;
  line-height: 24px;
  transition: background-color ease .3s;
}

.exit-button:active {
  color: $button-focus;
  outline: none;
}

.exit-button:hover {
  background-color: $button-hover;
}

@media screen and (max-width: 768px) {

  .agency__container {
    max-width: 320px;
  }

  .agency__contact-info {
    margin-bottom: 16px;
  }

  .agency__input-group {
    flex-direction: column;
    height: 100%;
    gap: 16px;
  }

  .agency__input-left,
  .agency__input-right {
    border-radius: 16px;
  }

  .svg-icon {
    display: none;
  }

  .agency__bottom-content {
    width: 100%;
  }

  .agency__policy {
    width: 100%;
    height: 100%;;
  }

  .checkbox-wrapper-28 {
    width: 100%;
    height: 100%;
    padding: 0;
  }

  .checkbox-wrapper-28 label:after {
    content: none;
  }

  .checkbox-wrapper-28 label:before {
    content: "";
    height: 24px;
    width: 24px;
    margin-right: 14px;
    padding: 10px;
    float: left;
    border: 2px solid $button-color;
    border-radius: 4px;
    transition: 0.15s all ease-out;
  }

  .checkbox-wrapper-28 label {
    display: flex;
    height: 100%;
  }

  .agency__buttons {
    flex-direction: column;
    gap: 16px;
  }

  .entry-button,
  .exit-button{
    width: 100%;
  }
}
