.best-advices__container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.advices-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 31.5px;
  align-items: stretch;
  justify-content: center;
}

.best-advices__header {
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  font-family: "Museo Sans Cyrl 500";
}

.best-advices {
  background: $light_gray;
}

.best-advices__block {
  display: flex;
  position: relative;
  width: 280px;
  padding: 4px;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 1px 4px 0 $smoke_white_shadow, 0 24px 48px 0 $grey_shadow;
  border-radius: 24px;
  background: $white;
}

.advice-img {
  max-height: 230px;
  border-radius: 20px;
  z-index: 1;
}

.block-descr {
  max-height: 48px;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch
}

.block-header {
  margin-bottom: 4px;
  font-family: "Museo Sans Cyrl 500";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  max-height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.best-advices__currency {
  font-family: "Museo Sans Cyrl 500";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: $button-color;
  padding: 4px 12px;
  padding-right: 18px;
}

.best-advices__value {
  font-family: "Museo Sans Cyrl 500";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: $black;
  z-index: 100;
}

.best-advices__price {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  z-index: 99;
  background-image: url('../../img/price.svg');
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 24px 0 24px 0;
}

.best-advices__block-descr {
  padding: 12px;
  max-width: 280px;
}

.advices-button {
  max-width: 187px;
  transition: background-color ease .3s;
  &:hover {
    background-color: $button-hover-color;
  }
}

@media screen and (max-width: 768px) {
  .advices-cards {
    flex-direction: column;
  }

  .best-advices__header {
    font-size: 32px;
    line-height: 40px;
  }

  .advices-cards {
    gap: 16px;
  }

  .best-advices__container {
    gap: 24px;
  }

  .best-advices__block {
    width: 100%;
  }

  .advice-img {
    max-width: 282px;
  }

  .block-header {
    max-width: 256px;
  }

  .advices-button {
    max-width: 100%;
  }
}
