.payment-bank-card__container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.unordered-list {
  padding-left: 18px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.unordered-list__item {
  list-style-type: disc;
  color: $button-color;
}

.payment-bank-card__form-block {
  width: 100%;
}

.payment-bank-card__form {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  align-self: stretch;
  border-radius: 16px;
  background: $light_gray;
  margin-bottom: 32px;
}

.payment-bank-card__input {
  height: 72px;
  padding: 24px;
  flex: 1 0 0;
  background-color: $light_gray;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  border: none;
  transition: background-color ease .3s;
  &:hover {
    background-color: $button-hover;
  }
}

.payment-bank-card__descr {
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $black;
}

.payment-bank-card__input:first-child {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.payment-bank-card__input:last-child {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.payment-bank-card__btn {
  transition: background-color ease .3s;
}

.payment-bank-card__fl {
  padding: 16px 24px;
  border-radius: 16px;
  border: 1px solid rgba(255, 118, 0, 0.50);
}

.fl__descr {
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $black;
}

.unordered-list__item-descr {
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}
