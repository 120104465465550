.agency-contacts {
  margin: 0 auto;
  padding: 24px;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  max-width: 800px;
  padding-left: 30px;
  padding-right: 14px;
  border-radius: 24px;
  background: $white;
  box-shadow: 0 1px 4px 0 $smoke_white_shadow, 0 24px 48px 0 $grey_shadow;
}

.contacts-for-agencies__last-child {
  padding-bottom: 64px;
}

.contacts-for-agencies {
  padding-bottom: 24px;
}

.agency-contacts__section-title {
  font-family: "Museo Sans Cyrl 500";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  color: $black;
}

.agency-contacts__header {
  font-family: "Museo Sans Cyrl 500";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  color: $black;
}

.label-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.contacts-label {
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $gray;
}

.agency-info {
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $black;
}
