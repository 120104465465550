.register-and-insurance__section {
  padding-top: 24px;
}

.register-and-insurance__container {
  max-width: 800px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: $white;
  border-radius: 24px;
  box-shadow: 0 1px 4px 0 $box-shadow-tabs  , 0 24px 48px 0 $box-sh-tabs;
}

.register-and-insurance__block {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.register-img {
  max-width: 238px;
}

.register-and-insurance__block:not(:last-child) {
  padding-bottom: 24px;
}

.register-and-insurance__block:not(:last-child) {
  border-bottom: 1px solid $inactive-icon;
}

@media (max-width: 768px) {
  .register-and-insurance__container {
    max-width: 288px;
  }

  .register-and-insurance__section {
    padding: 20px 16px 40px 16px;
  }

  .register-img {
    max-height: 340px;
  }
}
