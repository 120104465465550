$white: #fff;
$black: #000;
$grey: #8E8888;

$light_gray: #F6F5F9;
$light__orange: #FFF7F0;
$light__purple: #FFBDBD;

$inactive-icon: #CECECE;

$input-color: #F5F7FA;
$input-spliter: #DFDADAFF;

$header__button-hover: #FFCB9D;

$footer__backgraound: #EBEAEF;
$footer-text: #5b5b5b;
//$inactive-icon: #CECECE;

$text-link-hover: #FF8B26;

$button-hover: #FFE4CC;
$book-submit: #D3EADD;
$button-color: #FF7600;
$button-hover-color: #FF8C28;
$button-focus: #ED6E00;
$button-slider-color: #FF902E4C;
$btn-index-bgc: #FF902E4D;
$btn-disable: #C68B58;

$svg-ok: #FF7700;
$svg-tg: #229FDA;
$svg-vk: #0077FF;

$smoke_white_shadow: #EBE9E8BA;
$grey_shadow: #E6EBF566;
$gray: #8E8888;

$logo-hover: #7E654E;
$color__hover-clear: #FFE4CC33;
$burger__exit-background: #fffaf5;

$btn-index-bgc: #FF902E4D;
$btn-index-border: #ff7600;

$box-shadow-tabs: #ebe9e8ba;
$box-sh-tabs: #e6ebf566;
$bottom-line: #CECECE;
$occupied-seat: #ffcccc;

$box-shdw-header-top: #5E5F6066;
$box-shdw-header-bot: #474747BA;
$bcg-color-slider: #00000080;
$bcg-color-index: #00000066;

$table-head-background: #ffe4cc33;
