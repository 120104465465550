.go-back-link {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
  transition: color ease .3s, fill ease .3s;
  &:hover {
    color: $text-link-hover;
    fill: $text-link-hover;
  }
}

.go-back {
  padding-bottom: 24px;
}

.go-back__container {
  margin: 0 auto;
  padding-top: 24px;
  padding-bottom: 0;
  padding-left: 13px;
  background: none;
  box-shadow: none;
}

.go-back-link__descr {
  font-family: "Museo Sans Cyrl 500";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: $button-color;
}
