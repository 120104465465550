.awards {
  background-color: $light__orange;
}

.awards__header {
  margin-bottom: 40px;
  font-family: "Museo Sans Cyrl 500";
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  color: $black;
}

.awards-links {
  gap: 32px;
}

.awards-card {
  max-width: 384px;
  max-height: 192px;
  margin: 0;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
}

.first-award {
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;
  width: 40px;
  height: 40px;
  margin: 0;
  background-image: url('../../img/awards-bg-crown.svg');
  background-repeat: no-repeat;
  background-position: left;
}

.second-award {
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;
  width: 40px;
  height: 40px;
  margin: 0;
  background-image: url('../../img/awards-bg-crown.svg');
  background-repeat: no-repeat;
  background-position: left;
}

.third-award {
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;
  width: 40px;
  height: 40px;
  margin: 0;
  background-image: url('../../img/awards-bg-star.svg');
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
}

.award-card__descr {
  overflow: auto;
  text-overflow: ellipsis;
  font-family: "Museo Sans Cyrl 500";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  color: $black;
  letter-spacing: 0.3px;
}

.award-card__date {
  margin: 0;
  color: $black;
}

.award-card__date-paragraph {
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

@media screen and (max-width: 768px) {
  .awards__header {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 24px;
  }

  .awards-links {
    flex-direction: column;
  }

  .awards-card {
    gap: 0;
  }

  .award-title-bg {
    margin-bottom: 8px;
  }

  .award-card__descr {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  }
}

