.individual {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 64px;
  padding-top: 40px;
}

.individual__container {
  background-color: $white;
  border-radius: 24px;
  width: 800px;
  height: 528px;
  padding: 24px;
  box-shadow: 0 1px 4px 0 $box-shadow-tabs, 0 24px 48px 0 $box-sh-tabs;
}

.individual__bottom-content {
  width: 752px;
  height: 224px;
}

.individual__title {
  font-family: "Museo Sans Cyrl 700";
  margin-bottom: 24px;
  font-weight: 600;
}

.individual__title {
  font-size: 32px;
  line-height: 40px;
}

.individual__input-group,
.individual__input-group-passwords {
  position: relative;
  width: 752px;
  height: 72px;
  margin-bottom: 24px;
  display: flex;
  background-color: $input-color;
  border-radius: 16px;
}

.individual__input-group-passwords {
  margin-bottom: 8px;
}

.individual__input-group input,
.individual__input-group-passwords input {
  flex: 1;
  padding: 24px;
  background-color: $input-color;
  border: 2px solid $input-color;
  color: $black;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

.individual__name {
  border-radius: 16px;
}

.individual__phone-number {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.individual__email {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.individual__password {
  width: 752px;
  height: 104px;
  margin-bottom: 32px;
}

.individual__password-input {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.individual__password-repeat-input {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.individual__name,
.individual__phone-number,
.individual__email,
.individual__password-input,
.individual__password-repeat-input {
  cursor: pointer;
  transition: background-color .3s ease;
  border-radius: 16px;

  &:hover {
    background-color: $button-hover;
    border-color: $button-hover;
  }

  &:focus {
    background-color: $button-hover;
    border-color: $button-color;
  }
}

.individual__svg-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.individual__input-group input:focus,
.individual__input-group-passwords input:focus {
  outline: none;
}

.individual__exit-button {
  display: flex;
  width: 199px;
  height: 44px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid $button-color;
  border-radius: 8px;
  padding: 10px 32px;
  background: $white;
  color: $button-color;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
}

.individual__exit-button:focus {
  outline: none;
  color: $button-focus;
}

.individual__bottom-symbols {
  color: $gray;
  line-height: 24px;
}

.individual__footer {
  width: 398px;
  height: 28px;
  margin-bottom: 16px;
}

.login-footer {
  cursor: pointer;
}

.login-footer-orange {
  margin-left: 32px;
  color: $button-color;
  font-weight: 600;
  cursor: pointer;
}

.individual__data {
  color: $button-color;
  line-height: 24px;
  font-weight: 600;
}

@-webkit-keyframes draw-checkbox-28 {
  0% {
    stroke-dashoffset: 33;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes draw-checkbox-28 {
  0% {
    stroke-dashoffset: 33;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

.individual__buttons {
  display: flex;
}

.individual__entry-button {
  display: flex;
  padding: 10px 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--entry-background, $button-color);
  color: $white;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  border: none;
  margin-right: 16px;
  transition: background-color ease .3s;
}

.individual__entry-button:hover {
  background-color: $button-hover-color;
}

.individual__entry-button:focus {
  outline: none;
}

.individual__entry-button:active {
  background: var(--entry-hover-background, $button-focus);
}

.individual__exit-button {
  display: flex;
  width: 130px;
  height: 44px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid $button-color;
  border-radius: 8px;
  padding: 10px 40px;
  background: $white;
  color: $button-color;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  transition: background-color ease .3s;
}

.individual__exit-button:focus {
  outline: none;
  color: $button-focus;
}

.individual__exit-button:hover {
  outline: none;
  background-color: $button-hover;
}
