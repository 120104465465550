.my-tour-vladimir__phone-container {
  display: flex;
  gap: 32px;
}

.phone-number,
.working-hours-info, .moscow-info, .spb-info {
  min-width: 255px;
}

.my-tour-vladimir__phone-container {
  width: 100%;
}

.my-tour-vladimir__phone-label, .my-tour-vladimir__whatsapp-label, .my-tour-vladimir__email-label  {
  margin-bottom: 4px;
}

.my-tour-vladimir__working-hours {
  font-family: "Museo Sans Cyrl 500";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  color: $black;
}

.working-hours-info-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.my-tour-vladimir__working-hours-block {
  display: flex;
  gap: 32px;
}
