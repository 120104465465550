.gallery-swiper {
  position: relative;
  display: flex;
  gap: 12px;
  width: 100%;
  overflow: hidden;
}

.gallery-img {
  width: 40px;
  min-width: 40px;
  height: 300px;
  border-radius: 24px;
  cursor: pointer;
  position: relative;
  transition: width 0.3s ease;
  overflow: hidden;
}

.gallery-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 24px;
  background-color: rgba(255, 255, 255, 0.5);
  transition: opacity 0.2s;
}

.gallery-img:hover::before,
.gallery-img.active::before {
  opacity: 0;
}

.gallery-img img {
  height: 300px;
  border-radius: 24px;
  z-index: -1;
}

.gallery-img.active {
  width: 536px;
  margin: 0 4px;
}

.gallery-img:first-child.active {
  width: 540px;
  margin-left: 0;
}

.gallery-img:last-child.active {
  width: 540px;
  margin-right: 0;
}

.gallery-card-name {
  padding-bottom: 24px;
}

.gallery-buttons {
  margin-top: 12px;
  display: flex;
  gap: 24px;
  justify-content: center;
}

.arrow-left,
.arrow-right {
  cursor: pointer;
  transition: opacity 0.3s;
  user-select: none;
}

.arrow-left.disabled path,
.arrow-right.disabled path {
  stroke: #cecece;
}
