.tab-nav {
  max-width: 280px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.tab-btn {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $black;
  stroke: $inactive-icon;
}

.tab-nav-item-link {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
}

.tab-nav__svg-wrap {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.tab-btn-active {
  pointer-events: none;
  background-color: $white;
}

.tab-btn-hover {
  background-color: $button-hover;
}

.tab-btn-active,
.tab-btn-hover {
  color: black;
  stroke: $button-color;
  transition: stroke .3s ease;
  border-radius: 12px;
  box-shadow: 0 1px 4px 0 $smoke_white_shadow, 0 24px 48px 0 $grey_shadow;
}

.tab-btn svg {
  stroke: $inactive-icon;
}

.tab-btn-active svg,
.tab-btn-hover svg {
  stroke: $button-color;
}

.tab-btn-active > .tab-nav__svg-wrap > .fill-svg > path,
.tab-btn-hover > .tab-nav__svg-wrap > .fill-svg > path {
  fill: #FF7600;
}

.tab-descr {
  width: 264px;
  text-align: left;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $black ;
}

.select-bar-wrap__mobile {
  display: none;
}

.select-bar {}

.options-bar {
  display: none;
}

.show-options {
  display: block;
}

@media screen and (max-width: 768px) {

  .tab-nav {
    max-width: none;
  }

  .tab-nav__desktop {
    display: none;
  }

  .select-bar-wrap__mobile {
    display: block;
    border-radius: 16px;
  }

  .select-bar-svg {
    margin-left: auto;
  }

  .tab-nav__mobile {
    width: 290px;
    margin: auto;
    position: absolute;
    top: 112px;
    display: none;
    border-radius: 16px;
    background-color: white;
    box-shadow: 0 24px 48px 0 $box-shdw-header-top, 0 1px 4px 0 $box-shdw-header-bot;
    z-index: 120;
  }

  .tab-nav__mobile-active {
    display: block;
  }

  .tab-nav__mobile-rotate {
    transform: rotate(180deg);
  }

  .select-bar-svg {
  }

  .disable-radius {
    border-radius: 16px 16px 0 0;
    border-bottom: 2px solid $bottom-line;
  }
}
