.header-index {
  background-color: transparent;
  position: absolute;
  font-weight: 600;
}

.header-index {
  .header-text,
  .submenu-btn-3 {
    color: $white;
  }

  .header-text:hover {
    color: $button-hover;
    transition: all 0.2s ease;
  }

  .header__login-button {
    background: $btn-index-bgc;
    color: $white;
    border: 1px solid $btn-index-border;
  }

  .header__login-button:hover {
    background: $button-hover;
    color: $button-color;
    transition: all 0.1s ease;
    border: 1px solid $btn-index-border;
  }

  .header__logo-link:hover svg path {
    fill: $button-hover;
    transition: all 0.2s ease;
  }
}

@media screen and (max-width: 768px) {
  .header-index {
    .header__login {
      margin: 0;
      padding: 0;
    }
  }
}
