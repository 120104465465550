.bank-details-section {
  padding-top: 24px;
}

.bank-details__container {
  padding: 0;
  border-radius: 24px;
  border: 1px solid $white;
  box-shadow: 0 1px 4px 0 $box-shadow-tabs  , 0 24px 48px 0 $box-sh-tabs;
}

.section-title {
  padding: 24px;
}

.section-title {
  border-bottom: 1px solid $inactive-icon;
}

.section-descr-block {
  border-top: 1px solid $inactive-icon;
}

.bank-details-info {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.ogranizations-container {
  display: flex;
  flex-direction: column;
}

.list-details {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.list-details:not(:last-child) {
  border-bottom: 1px solid $inactive-icon;
}

.company-bank-details {
  flex-direction: column;
  gap: 12px;
}



.bank-details__header {
  -webkit-line-clamp: 2;
  max-width: 740px;
}

@media (max-width: 768px) {
  .bank-details-section {
    padding: 20px 16px 40px 16px;
  }

  .section-title {
    min-height: 128px;
  }
}
