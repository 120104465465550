.placing-tours__container {
  max-width: 800px;
}

.placing-tours-block {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: $white;
  border-radius: 24px;
  box-shadow: 0 1px 4px 0 $box-shadow-tabs  , 0 24px 48px 0 $box-sh-tabs;
}

.placing-tours-block:not(:last-child) {
  margin-bottom: 24px;
}

.api-block {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  word-wrap: break-word;
}

.api-table {
  margin-bottom: 0;
  border-collapse: collapse;
  border-spacing: 0;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $black;
  overflow: auto;
  max-width: 752px;
}

.api-table>thead>tr>th,
.api-table>tbody>tr>td {
  padding: 16px 24px;
}

.api-table__block {
  border: 1px solid $inactive-icon;
  border-radius: 24px;
}

.api-table__header:nth-child(2) {
  max-width: 218px;
}

.placing-tours-block__number {
  font-family: "Museo Sans Cyrl 500";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  color: $button-color;
}

.placing-tours__form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.label-container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// .label-container > .checkmark:nth-child(2) {
//   padding-left: 42px;
// }

/* Hide the browser's default checkbox */
.label-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border: 2px solid $button-color;
  border-radius: 4px;
  background-color: transparent;
}

.label-container:hover input ~ .checkmark {
  background-color: transparent;
}

/* When the checkbox is checked, add a blue background */
.label-container input:checked ~ .checkmark {
  background-color: transparent;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.3307 2.66667C15.6989 3.03486 15.7321 3.59865 15.4048 3.92593L5.33073 14L0.664062 9.33333C0.295873 8.96514 0.262708 8.40135 0.589988 8.07407C0.917268 7.74679 1.48106 7.77996 1.84925 8.14815L5.18258 11.4815L14.0715 2.59259C14.3987 2.26531 14.9625 2.29848 15.3307 2.66667Z' fill='%23FF7600'/%3e%3c/svg%3e ");
}

/* Show the checkmark when checked */
.label-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.label-container .checkmark:after {
  left: 2px;
  top: 1px;
  width: 16px;
  height: 16px;
}

.additional-checkbox, .additional-checkbox > .checkbox {
  margin-left: 42px;
}

.form-block__checkboxes {
  max-height: 100%;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.generate-btn {
  width: 199px;
  height: 44px;
  padding: 10px 40px;
  background-color: $button-color;
  border-radius: 8px;
  color: $white;
}

@media (max-width: 768px) {
  .placing-tours__container {
    max-width: 288px;
  }

  .placing-tours__section {
    padding: 20px 16px 40px 16px;
  }

  .api-block {
    max-width: 288px;
  }

  .api-table__block {
    max-width: 240px;
    max-height: 400px;
    overflow: scroll;
  }

}
