.account__sidebar {
  width: 280px;
  height: 88px;
  display: grid;
  gap: 8px;
  font-weight: 300;
}

.account__sidebar-first-btn-content,
.account__sidebar-second-btn-content {
  padding: 8px;
  display: flex;
  line-height: 24px;
  align-items: center;
}

.account__sidebar-first,
.account__sidebar-second {
  width: 280px;
  height: 40px;
}

.account__sidebar-first-btn,
.account__sidebar-second-btn {
  width: 280px;
  height: 40px;
  padding: 0;
}

.account__sidebar-first-btn,
.account__sidebar-second-btn {
  border: none;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  background-color: transparent;
}

.account__sidebar-first-btn {
  background-color: $white;
}

.account__sidebar-first-descr {
  justify-content: center;
}

.tab-pane {
  display: none;
}
 
.tab-pane.active {
  display: block;
}

.tab-item.active .account__sidebar-first-btn {
  background-color: $white;
  box-shadow: 0 1px 4px 0 $box-shadow-tabs, 0 24px 48px 0 $box-sh-tabs;
}

.tab-item.active .account__sidebar-first-btn-svg path {
  fill: #FF7600; 
}

.tab-item.active .account__sidebar-second-btn {
  background-color: $white;
  box-shadow: 0 1px 4px 0 $box-shadow-tabs, 0 24px 48px 0 $box-sh-tabs;
}

.tab-item.active .account__sidebar-first-btn-svg path {
  fill: #FF7600; 
}

.tab-item:not(.active) .account__sidebar-first-btn {
  background-color: transparent;
}

.tab-item:not(.active) .account__sidebar-first-btn-svg path {
  fill: #CECECE;
}

.tab-btn-sidebar {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $black;
  stroke: $inactive-icon;
}

@media screen and (max-width: 768px) {

}


