.header {
  position: static;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 110;
  background-color: $white;
  height: 72px;
  display: flex;
  align-items: center;
}

.header-text,
.header-index-text {
  font-family: "Museo Sans Cyrl 500";
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
}

.header-text:hover {
  font-family: "Museo Sans Cyrl 500";
  font-weight: 600;
}

.header__container {
  width: 1216px;
  height: 40px;
  margin: 0 auto;
}

.header__content,
.header__menu-container,
.header__menu-info {
  display: flex;
  align-items: center;
}

.header__content {
  width: 1216px;
  height: 40px;
  display: flex;
  align-items: center;
}

.header__logo-content {
  height: 40px;
  margin-right: 112px;
}

.header__menu-container {
  gap: 32px;
}

.header__menu-btn-container {
  width: 117px;
}

.header__menu {
  width: 591px;
  height: 24px;
  margin-right: 91px;
}

.header__menu-container {
  width: 592px;
  height: 24px;
}

.header-item1 {
  width: 71px;
}

.header__content__mobile {
  display: none;
}

.header-item2 {
  width: 103px;
}

.header-item3 {
  width: 45px;
}

.header-item4 {
  width: 82px;
}

.header-item5 {
  width: 163px;
}

.header__menu-info {
  width: 221px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  gap: 24px;
}

.header__login-button {
  width: 80px;
  height: 40px;
  padding: 5px 16px;
  border-radius: 8px;
  background: $button-hover;
  color: $button-color;
  border: none;
  cursor: pointer;
  font-family: "Museo Sans Cyrl 500";
  z-index: 1;
}

.header__login-button:hover {
  background: $header__button-hover;
  border: none;
  color: $button-color;
  transition: all 0.1s ease;
}

.submenu-btn-1,
.submenu-btn-2,
.submenu-btn-3,
.header__menu-content-name {
  cursor: default;
}

.dropdown-marge {
  margin-bottom: 16px;
}

.dropdown-marge-4 {
  margin-bottom: 4px;
}

.header__menu-content {
  font-family: "Museo Sans Cyrl 700";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.header__menu-content-name {
  font-family: "Museo Sans Cyrl 500";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.header__menu-contact-info-number {
  font-family: "Museo Sans Cyrl 700";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.header__menu-contact-info {
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  cursor: default;
}

.dropdown-list {
  margin-bottom: 16px;
}

.header__menu-content {
  color: $gray;
}

.header__logo-link:hover svg path {
  fill: $logo-hover;
  transition: all 0.2s ease;
}

.header__menu-title:hover {
  color: $button-color;
}

.submenu-btn-3 {
  font-family: "Museo Sans Cyrl 700";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

.sub-menu1,
.sub-menu2,
.sub-menu3 {
  position: absolute;
  visibility: hidden;
  opacity: 0;
}

.sub-menu1.open,
.sub-menu2.open,
.sub-menu3.open {
  visibility: visible;
  opacity: 1;
  width: 240px;
  height: auto;
  margin-top: -40px;
  padding: 16px;
  margin-left: -16px;
  background-color: $white;
  border-radius: 16px;
  box-shadow: 0 24px 48px 0 $box-shdw-header-top, 0 1px 4px 0 $box-shdw-header-bot;
  transition: opacity 0.3s ease;
}

.header__menu-content:hover {
  color: $button-color;
  transition: 0.2s ease;
}

.header__menu .header__sub-menu .header__menu-item1:first-child {
  margin-top: 4px;
}

.header__menu .header__sub-menu .header__menu-item1:nth-child(2),
.header__menu .header__sub-menu .header__menu-item1:nth-child(4) {
  margin-top: 16px;
  margin-bottom: 4px;
}

.header__menu-item-1 {
  margin-right: 32px;
  width: 74px;
  height: 24px;
}

.header__menu-item-2 {
  margin-right: 32px;
  width: 112px;
  height: 24px;
}

.header__menu-item-3 {
  margin-left: -6px;
  width: 228px;
}

.header__menu-item-4 {
  margin-left: -136px;
  width: 206px;
}

.header__nav-title-5 {
  margin-left: -114px;
  width: 222px;
  padding-left: 32px;
}

.burger-menu {
  display: none;
}

@media screen and (max-width: 768px) {

  .header {
    flex-direction: column;
  }

  .header__container {
    max-width: 320px;
  }

  .header__content {
    display: none;
  }

  .header__content__mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
  }

  .header__burger {
    border: 0;
    height: 24px;
    margin-right: 12px;
    background: transparent;
  }

  .header__logo-link {
    margin-right: 0;
    width: 104px;
    height: 24px;
  }

  .header__logo {
    margin-top: 0;
  }

  .burger-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    display: none;
    background: white;
  }

  .burger-menu__container {
    margin: auto;
    max-width: 320px;
    font-weight: 300;
  }

  .active {
    display: block;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .burger-menu ul {
    list-style-type: none;
    padding: 0;
  }

  .burger-menu__header {
    max-width: 320px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    border-bottom: $inactive-icon solid 1px;
  }

  .burger-menu__links-and-phones {
    padding: 24px 16px;
  }

  .burger-menu__nav-block {
    border-bottom: $inactive-icon solid 1px;
  }

  .burger-menu__contacts-title {
    padding-top: 16px;
    padding-bottom: 12px;
    font-weight: 600;
  }

  .burger-menu__phone-block {
    margin-bottom: 16px;
  }

  .burger-menu__phone-block:last-child {
    margin-bottom: 0;
  }

  .burger-menu__phone {
    margin-bottom: 4px;
    font-weight: 600;
  }

  .burger-menu__phone-span {
    color: $button-color;
  }

  .menu__exit {
    border-top: $button-hover solid 1px;
    background-color: $burger__exit-background;
    padding: 12px 16px;
  }

  .burger-menu__link {
    margin-bottom: 16px;
  }

  .dropdown-block-active {
    font-weight: 600;
  }

  .burger-menu__dropdown-block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  .burger-menu__icon-wrap {
    width: 24px;
    height: 24px;
  }

  .burger-menu__dropdown-list {
    display: none;
  }

  .burger-menu__dropdown-item {
    margin-bottom: 16px;
  }

  .burger-menu__dropdown-item a{
    color: $gray;
  }

  .burger-menu__dropdown-item:last-child {
    margin-bottom: 24px;
  }

  .dropdown-block-active img{
    transform: rotate(180deg);
  }

  .dropdown-active {
    display: block;
  }

  .header__burger-and-logo {
    display: flex;
  }

  .burger-menu .header__burger-icon {
    width: 24px;
    height: 24px;
  }

  .burger-menu .header__login-button-index {
    color: $button-color;
    border: $button-hover solid 1px;
  }
}
