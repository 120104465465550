.schedule-of-arrival__section {
  padding-top: 24px;
  padding-bottom: 64px;
}

.schedule-of-arrival__container {
  max-width: 800px;
  padding: 0;
  background-color: $white;
  border-radius: 24px;
  box-shadow: 0 1px 4px 0 $box-shadow-tabs  , 0 24px 48px 0 $box-sh-tabs;
}

.schedule-of-arrival__header {
  padding: 24px;
}

.schedule-of-arrival__table {
  width: 100%;
  margin-bottom: 0;
  border-top: 1px solid $inactive-icon;
}

.schedule-of-arrival__container> .schedule-of-arrival__descr >.schedule-of-arrival__table>tbody>td {
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

.schedule-of-arrival__container> .schedule-of-arrival__descr > .schedule-of-arrival__table > tbody > td {
  padding: 24px 16px;
}

.schedule-of-arrival__container> .schedule-of-arrival__descr > .schedule-of-arrival__table > thead {
  height: 144px;
  background: $table-head-background;
}

.table-months {
  padding: 24px 16px;
}

.schedule-of-arrival__table>th,
td {
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  padding: 23px 10px;
}

.last-programm-name {
  border-bottom: none;
}

.schedule-of-arrival__table>th {
  position: sticky;
  top: 0;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

.programm-name {
  padding: 24px 16px;
}

.table-head-dates {
  padding: 24px;
}

.three-to-one-arrival {
  border-bottom: none;
  border-top: none;
}

.schedule-of-arrival__table>tr {
  border: 1px solid $inactive-icon;
  z-index: 100;
}

.table-bottom-border {
  border-bottom: 1px solid $inactive-icon;
}

.schedule-of-arrival__descr {
  max-width: 800px;
  overflow: auto;
}

table {
  max-width: 800px;
  overflow: scroll;
}



.schedule-of-arrival__table > thead > tr > .table-header {
  border-radius: 0;
}


@media (max-width: 768px) {
  .schedule-of-arrival__container {
    max-width: 288px;
  }

  .schedule-of-arrival__descr {
    max-width: 288px;
    max-height: 428px;
  }

  .schedule-of-arrival__section {
    padding: 20px 16px 40px 16px;
  }
}
