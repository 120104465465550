.tour-details {
  width: auto;
  padding-bottom: 24px;
}

.tour-details:last-of-type {
  padding-bottom: 64px;
}

.tour-details__button-back-arrow {
  width: 24px;
  height: 24px;
}

.tour-details__button-back-text,
.tour-details__result-link-text {
  font-family: "Museo Sans Cyrl 500";
  font-weight: 600;
  color: $button-color;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
}

.tour-details__container {
  width: 800px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0;
}

.tour-details__button-back {
  width: 83px;
  height: 24px;
  line-height: 24px;
  display: flex;
  gap: 12px;
  margin: 24px 0;
  align-items: center;
}

.tour-details__content-card {
  background-color: $white;
  box-shadow: 0 1px 4px 0 $box-shadow-tabs, 0 24px 48px 0 $box-sh-tabs;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
}

.tour-details__content-input-card {
  padding: 24px 24px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.tour-details__result {
  padding: 0 24px 24px 24px;
}

.tour-details__result-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 752px;
  border-radius: 24px;
  border: 2px solid var(--border-color, $button-hover);
  background: linear-gradient(275deg, rgba(255, 118, 0, 0.1) 0.3%, rgba(255, 184, 0, 0.01) 106.59%), $white;
  border-radius: 24px;
  padding: 24px;
}

.tour-details__result-info {
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-wrap: break-word;
}

.tour-details__result-content {
  display: flex;
  gap: 24px;
}

.tour-details__result-content-date,
.tour-details__result-content-price {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.tour-details__result-content-date {
  width: 240px;
}

.tour-details__result-content-price {
  width: 184px;
}

.tour-details__card-name {
  font-family: "Museo Sans Cyrl 700";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

.tour-details__result-total-cost {
  font-family: "Museo Sans Cyrl 700";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.tour-details__dropdown {
  display: flex;
  justify-content: space-between;
  height: 80px;
  padding: 24px;
  border-top: 1px solid var(--border-color, $bottom-line);
  background: var(--background-color, $white);
  cursor: pointer;
}

.tour-details__content-card-pic {
  width: 800px;
  height: 304px;
  padding: 4px 4px 0 4px;
}

.pic-tour-details {
  border-radius: 24px;
}

.tour-details__tour-name {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.tour-details__tour-name-text {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.tour-details__tour-buttons {
  display: flex;
  gap: 16px;
}

.tour-details__tour-name-btn {
  display: flex;
  width: 240px;
  height: 44px;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--border-color, $button-color);
  background: var(--background-color, $white);
  color: $button-color;
  font-family: "Museo Sans Cyrl 500";
  font-weight: 600;
  cursor: pointer;
}

.tour-details__tour-name-btn:hover {
  background: var(--background-color, $button-hover);
  transition: background-color 0.3s ease;
}

.tour-details__tour-name-text-main {
  font-family: "Museo Sans Cyrl 700";
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
}

.tour-name-dropdown {
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  cursor: pointer;
}

.tour-details__input-block {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.tour-details__calendar {
  width: 100%;
  //width: 249.33px;
  height: 72px;
}

.details-tour__calendar-form {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  //width: 249.33px;
  height: 72px;
}

.details-tour__calendar-form > .custom-date {
  width: 100%;
  //width: 249.33px;
}

.tour-details__input-container {
  position: relative;
  background-color: $input-color;
  height: 72px;
  border-radius: 16px;
  display: flex;
  align-items: center;
}

.tour-details__checkbox-container {
  height: 100%;
  //height: 116px;
  padding: 2px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.tour-details__checkbox-text {
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

.tour-details__checkbox-price {
  height: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.tour-details__result-text {
  width: 152px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.tour-details__svg-container {
  width: 24px;
  height: 24px;
}

.tour-details__line {
  display: flex;
  justify-content: center;
  height: 0;
}

.checkbox-wrapper-1,
.checkbox-wrapper-2,
.checkbox-wrapper-3 {
  --size: 28px;
  position: relative;
  height: 28px;
  display: flex;
  align-items: center;
}

.checkbox-wrapper-1 *,
.checkbox-wrapper-1 *:before,
.checkbox-wrapper-1 *:after,
.checkbox-wrapper-2 *,
.checkbox-wrapper-2 *:before,
.checkbox-wrapper-2 *:after,
.checkbox-wrapper-3 *,
.checkbox-wrapper-3 *:before,
.checkbox-wrapper-3 *:after,
.checkbox-wrapper-4 *,
.checkbox-wrapper-4 *:before,
.checkbox-wrapper-4 *:after {
  box-sizing: border-box;
}

.checkbox-wrapper-1 .promoted-input-checkbox,
.checkbox-wrapper-2 .promoted-input-checkbox,
.checkbox-wrapper-3 .promoted-input-checkbox,
.checkbox-wrapper-4 .promoted-input-checkbox {
  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.checkbox-wrapper-1 input:checked ~ svg,
.checkbox-wrapper-2 input:checked ~ svg,
.checkbox-wrapper-3 input:checked ~ svg,
.checkbox-wrapper-4 input:checked ~ svg {
  padding-right: 2px;
  height: calc(var(--size) * 0.6);
  -webkit-animation: draw-checkbox-28 ease-in-out 0.2s forwards;
  animation: draw-checkbox-28 ease-in-out 0.2s forwards;
}

.checkbox-wrapper-1 label,
.checkbox-wrapper-2 label,
.checkbox-wrapper-3 label,
.checkbox-wrapper-4 label {
  color: $black;
  line-height: var(--size);
  cursor: pointer;
  position: relative;
}

.checkbox-wrapper-1 label:before,
.checkbox-wrapper-2 label:before,
.checkbox-wrapper-3 label:before,
.checkbox-wrapper-4 label:before {
  content: "";
  content: "";
  height: 24px;
  width: 24px;
  margin-right: 14px;
  margin-top: 3px;
  float: left;
  border: 2px solid $button-color;
  border-radius: 4px;
  transition: 0.15s all ease-out;
}

.checkbox-wrapper-1 svg,
.checkbox-wrapper-2 svg,
.checkbox-wrapper-3 svg,
.checkbox-wrapper-4 svg {
  stroke: $button-color;
  stroke-width: 3px;
  height: 0;
  width: calc(var(--size) * 0.6);
  position: absolute;
  left: calc(var(--size) * 0.21);
  top: calc(var(--size) * 0.2);
  stroke-dasharray: 33;
}

.tour-details__dropdown-text {
  font-family: "Museo Sans Cyrl 700";
  display: flex;
  align-items: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.tour-details__dropdown-name {
  font-family: "Museo Sans Cyrl 700";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.reservation {
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
}

.tour-details__result-content-place {
  width: 232px;
  display: flex;
  gap: 16px;
}

.tour-details__result-content-place-container {
  position: relative;
  display: inline-block;
  width: 64px;
  height: 64px;
}

.tour-details__result-content-place-container img {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 12.8px;
  border: 3px solid var(--border-color, $button-hover);
  background: $light__purple;
}

.tour-details__result-place-number {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  bottom: 0;
  right: 0;
  border-radius: 8px 0 8px 0;
  background: var(--background-color, $button-hover);
  padding-top: 1px;
}

.top-right-svg,
.bottom-left-svg {
  position: absolute;
  width: 8px;
  height: 8px;
}

.top-right-svg {
  top: -16px;
  right: 3px;
}

.bottom-left-svg {
  bottom: 12px;
  left: -7px;
}

.tour-details__result-link {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.tour-details__result-buttons {
  display: flex;
  gap: 24px;
  align-items: center;
}

.tour-details__hotels-cards {
  display: flex;
  justify-content: space-between;
}

.tour-details__hotel-card {
  width: 364px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.tour-details__hotel-pic {
  display: flex;
  gap: 16px;
}

.tour-details__hotel-image-container {
  border-radius: 9.33px;
  display: flex;
  align-items: center;
  width: 56px;
  height: 56px;
}

.tour-details__hotel-image {
  border-radius: 9.33px;
  width: 100%;
  height: 100%;
}

.tour-details__hotel-text {
  width: 292px;
  font-family: "Museo Sans Cyrl 700";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.tour-details__hotel-info {
  width: 364px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.tour-details__hotel-info-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.tour-details__link-container {
  width: 67px;
}

.tour-details__feedback-people {
  display: flex;
  gap: 24px;
  margin-top: 24px;
}

.tour-details__feedback-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 4px solid $button-hover;
  border-radius: 24px;
  gap: 24px;
  padding: 20px;
}

.tour-details__account-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  width: 236px;
  line-height: 24px;
  overflow-wrap: break-word;
}

.public-places {
  display: flex;
  gap: 16px;
}

.public-places.hidden {
  display: none;
}

.third-input {
  position: relative;
  height: 100%;
}

.tour-details__first-input-holder {
  position: relative;
  height: 100%;
}

.tour-details__second-input-holder > .choices > .choices__inner,
.tour-details__first-input-holder > .choices > .choices__inner,
.tour-details__third-input-holder > .choices > .choices__inner,{
  position: relative;
  padding-top: 32px;
  border-radius: 12px;
  background-color: $input-color;
  border: 2px solid $input-color;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  transition: background-color 0.3s ease;
  z-index: 100;

  &:hover {
    background-color: $button-hover;
  }
}

.tour-details__second-input-holder > .is-focused.choices > .choices__inner,
.tour-details__third-input-holder > .is-focused.choices > .choices__inner,
.tour-details__first-input-holder > .is-focused.choices > .choices__inner {
  border-color: $button-color;
  background-color: $button-hover;
}

.tour-details__second-input-holder > .is-open.choices > .choices__inner,
.tour-details__third-input-holder > .is-open.choices > .choices__inner,
.tour-details__first-input-holder > .is-open.choices > .choices__inner {
  border-color: $button-color;
  background-color: $button-hover;
}

.tour-details__first-input-select,
.tour-details__third-input-select,
.tour-details__second-input-select,
.tour-details__second-input-holder,
.tour-details__third-input-holder,
.choices__inner {
  width: 100%;
  //width: 249.33px;
  height: 72px;
}

.tour-details__third-input-select > .tour-details__third-input-holder > .choices::before {
  position: absolute;
  content: "Количество туристов";
  left: 24px;
  top: 12px;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $grey;
  background-color: var(--primary-light);
  z-index: 112;
}

.tour-details__second-input-select > .tour-details__second-input-holder > .choices::before {
  position: absolute;
  content: "Гостиница";
  left: 24px;
  top: 12px;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $grey;
  background-color: var(--primary-light);
  z-index: 112;
}

.tour-details__first-input-select > .tour-details__first-input-holder > .choices::before {
  position: absolute;
  content: "Размещение";
  left: 24px;
  top: 12px;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $gray;
  background-color: var(--primary-light);
  z-index: 112;
}

.tour-details__second-input-holder > .choices > .choices__inner > .choices__list > .is-active,
.tour-details__third-input-holder > .choices > .choices__inner > .choices__list > .is-active {
  z-index: 110;
}

.tour-details__second-input-holder
> .choices
> .choices__inner
> .choices__list
> .choices__list--single
.choices__item {
  padding-left: 24px;
}

.tour-details__third-input-holder
> .choices
> .choices__inner
> .choices__list
> .choices__list--single
.choices__item {
  padding-left: 24px;
}

.tour-details__input-container svg {
  z-index: 101;
  width: 4px;
  height: 36px;
}

.arrange-tour-form,
.reservation-hidden-link-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.tour-details__dropdown-hidden {
  overflow: hidden;
  height: 0;
  padding: 0 24px;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
}

.tour-details__dropdown-hidden-card {
  overflow: hidden;
  height: 0;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
}

.bus-select-hidden {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.tour-details__bus-select-hidden,
.reservation-hidden-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 24px;
}

.tour-details__dropdown-bus1-places {
  width: 752px;
  height: 256px;
  padding: 24px 40px;
  border-radius: 24px;
  border: 2px solid var(--border-color, $inactive-icon);
  display: grid;
  grid-template-columns: repeat(13, 40px);
  grid-template-rows: repeat(5, 32px);
  grid-gap: 12px;
}

.svg-seat-container {
  width: 40px;
  height: 32px;
  cursor: pointer;
}

.svg-seat-container svg {
  width: 40px;
  height: 32px;
}

.bus-place-top-left {
  display: grid;
  grid-template-columns: repeat(6, 40px);
  grid-template-rows: repeat(2, 32px);
  grid-gap: 12px;
  grid-column: 1 / span 6;
  grid-row: 1 / span 2;
}

.bus-place-bottom {
  display: grid;
  grid-template-columns: repeat(13, 40px);
  grid-template-rows: repeat(2, 32px);
  grid-gap: 12px;
  grid-column: 1 / span 13;
  grid-row: 4 / span 2;
}

.bus-place-top-right {
  display: grid;
  grid-template-columns: repeat(5, 40px);
  grid-template-rows: repeat(2, 32px);
  grid-gap: 12px;
  grid-column: 9 / span 5;
  grid-row: 1 / span 2;
}

.bus-main-place {
  display: grid;
  grid-template-columns: repeat(1, 40px);
  grid-template-rows: repeat(1, 32px);
  grid-gap: 12px;
  grid-column: 13 / span 1;
  grid-row: 3 / span 1;
}

.tour-details__dropdown-bus2-places {
  width: 450px;
  height: 230px;
  margin: 0 auto;
  padding: 24px 56px 24px 130px;
  border-radius: 24px;
  border: 2px solid var(--border-color, $inactive-icon);
  display: grid;
}

.mini-bus-top-seats {
  display: grid;
  grid-template-columns: repeat(4, 40px);
  grid-template-rows: repeat(2, 32px);
  grid-gap: 12px;
  grid-column: 2 / span 5;
  grid-row: 1 / span 2;
}

.mini-bus-bottom-seats {
  display: grid;
  grid-template-columns: repeat(5, 40px);
  grid-template-rows: repeat(2, 32px);
  grid-gap: 12px;
  grid-column: 1 / span 5;
  grid-row: 3 / span 2;
  margin-top: 26px;
}

.tour-details__dropdown-place-info {
  display: flex;
  justify-content: center;
  gap: 34px;
}

.tour-details__dropdown-place-reserved,
.tour-details__dropdown-place-free {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.tour-details__table-cost {
  width: 752px;
  border-radius: 24px;
  border: 1px solid var(--border-color, $inactive-icon);
}

.bottom-tr th {
  border-bottom: none;
}

.detail-cost-hidden,
.details-about-hidden,
.reservation-hidden {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.tour-details__additional-cost,
.reservation-hidden-list-content,
.reservation-hidden-link {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.tour-details__list {
  display: flex;
  line-height: 24px;
  gap: 12px;
}

.tour-details__list-point {
  height: 24px;
}

.tour-details__list svg {
  width: 16px;
  height: 16px;
  margin-bottom: 8px;
}

.reservation-hidden-policy {
  width: 752px;
  height: 96px;
  padding: 24px;
  border-radius: 24px;
  border: 2px solid var(--border-color, $button-hover);
}

.bus1-select-hidden.show {
  padding: 0 24px 24px;
}

.detail-cost-hidden.show {
  height: 420px;
  padding: 0 24px 24px;
}

.details-about-hidden.show {
  padding: 0 24px 24px;
  height: 384px;
}

.reservation-hidden.show {
  padding: 0 24px 24px;
  height: 800px;
}

.tour-program-hidden.show {
  height: 2696px;
}

.tour-program {
  display: flex;
  justify-content: space-between;
  width: 800px;
  height: 80px;
  padding: 24px;
  cursor: pointer;
}

.tour-detail-table-main {
  margin: 0;
}

.tour-detail-table-head {
  width: 192px;
  height: 168px;
  padding: 24px;
}

.tour-detail-table-places {
  width: 140px;
  height: 72px;
  padding: 24px;
}

.tour-detail-table-date {
  width: 280px;
  height: 96px;
  padding: 24px;
}

.tour-detail-table-name {
  width: 192px;
  height: 72px;
}

.tour-detail-table-price {
  width: 140px;
  height: 72px;
  padding: 24px;
}

.tour-details__dropdown-hidden-name {
  height: 80px;
  border-top: 1px solid var(--border-color, $inactive-icon);
  padding: 24px;
}

.tour-details__day-descr {
  padding: 0 24px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.tour-details__day1-descr-item-col,
.reservation-hidden-link-descr {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.tour-details__day1-descr-item,
.tour-details__result-content-display {
  display: flex;
  gap: 12px;
}

.tour-name-dropdown-hidden.show {
  height: 240px;
  padding: 0 4px 4px 4px;
  border-radius: 36px;
}

.details-tour__custom-date {
  background-image: url(../../img/calendar.svg);
  background-repeat: no-repeat;
  background-position: 325px;
  //background-position: 202px;
  cursor: pointer;
}

.tour-details__body {
  .air-datepicker-body--day-name {
    font-family: "Museo Sans Cyrl 300" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 300 !important;
    line-height: 24px !important;
    color: #8e8888 !important;
    width: auto !important;
    height: auto !important;
    text-transform: lowercase !important;
  }

  .air-datepicker.-active-:not(.-custom-position-) {
    width: 371px !important;
    //width: 245.33px !important;
  }

  .air-datepicker--navigation {
    width: 371px !important;
    //width: 245.33px !important;
  }

  .air-datepicker--content {
    width: 356px !important;
    //width: 230.33px !important;
  }

  .air-datepicker-cell.-disabled- {
    cursor: not-allowed;
    color: $black;
  }

  .air-datepicker-cell.empty:hover {
    color: unset !important;
    background: unset !important;
  }

  .air-datepicker {
    z-index: 100 !important;
  }

  .air-datepicker-cell.-day-::after {
    content: '';
    width: 4px;
    height: 4px;
    position: absolute;
    top: 28px;
  }

  .empty {
    cursor: not-allowed;
  }

  .free {
    cursor: pointer;
  }

  .free::after {
    background-image: url(../../img/indicator-free.svg);
  }

  .part-time {
    cursor: pointer;
  }

  .part-time::after {
    background-image: url(../../img/indicator-part-time.svg);
  }

  .occupied {
    color: unset !important;
    background: unset !important;
    cursor: not-allowed;
  }

  .occupied::after {
    background-image: url(../../img/indicator-occupied.svg);
  }
}

.svg-seat-container rect {
  transition: fill 0.1s ease;
}

.occupied-seat rect {
  fill: $occupied-seat;
}

.occupied-seat svg {
  cursor: not-allowed;
}

@media screen and (max-width: 768px) {
  .tour-details__container {
    max-width: 288px;
  }

  .tour-details__button-back {
    margin-top: 20px;
  }

  .tour-details__content-container {
    width: 100%;
  }

  .tour-details__dropdown {
    height: 100%;
  }

  .feedback-card-container-name {
    flex-direction: column;
    gap: 12px;
    align-items: start;
    padding: 24px;
  }

  .tour-details__content-input-card {
    height: 100%;
  }

  .tour-details__content-card-pic {
    position: relative;
    width: 288px;
    height: 300px;
  }

  .tour-details__tour-buttons {
    flex-direction: column;
  }

  .tour-details__content-card-pic img {
    object-fit: cover;
  }

  .tour-name-dropdown-hidden.show {
    height: 304px;
  }

  .tour-details__dropdown-hidden iframe {
    height: 300px;
  }

  .map-widget-map-component {
    width: 288px;
    height: 300px;
  }

  .tour-details__input-container svg {
    display: none;
  }

  .checkbox-wrapper-1,
  .checkbox-wrapper-2,
  .checkbox-wrapper-3,
  .checkbox-wrapper-3 {
    height: 100%;
  }

  .checkbox-wrapper-1 label:before {
    margin-bottom: 20px;
  }

  .checkbox-wrapper-2 label:before {
    margin-bottom: 68px;
  }

  .checkbox-wrapper-3 label:before {
    margin-bottom: 44px;
  }

  .tour-details__result-content {
    flex-direction: column;
  }

  .tour-details__result-container {
    width: 100%;
    gap: 24px;
  }

  .tour-details__result-content {
    gap: 0;
  }

  .tour-details__result-content-date {
    margin-bottom: 16px;
  }

  .tour-details__result-content-price {
    margin-bottom: 24px;
  }

  .tour-details__result-content-place {
    width: 100%;
    flex-direction: column;
  }

  .tour-details__result-text {
    width: 100%;
  }

  .tour-details__result-buttons {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 16px;
  }

  .tour-details__result-buttons .button {
    align-self: center;
  }

  .details-tour__calendar-form {
    width: 100%;
  }

  .tour-program {
    width: 288px;
  }

  .tour-cost-descr {
    width: 100%;
    height: 100%;
  }

  .tour-cost-descr-name {
    width: 100%;
    height: 100%;
  }

  .tour-cost-table {
    width: 100%;
    height: 100%;
  }

  .common-text-name {
    width: 100%;
    height: 100%;
  }

  .detail-cost-hidden.show {
    height: 640px;
  }

  .tour-detail-table-price {
    padding: 0;
  }

  .tour-detail-table-main {
    width: 750px;
    height: 240px;
    margin-bottom: 0;
  }

  .tour-details__table-cost-container {
    width: 240px;
    min-height: 245.5px;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .tour-details__table-cost {
    height: 240px;
  }
  .tour-detail-table-main {
   min-height: 240px;
  }

  .tour-details__table-cost-container::-webkit-scrollbar {
    height: 6px;
    width: 100px;
  }

  .tour-details__table-cost-container::-webkit-scrollbar-thumb {
    background: var(--your-color-variable, $button-hover);
    border-radius: 8px;
  }

  .tour-details__table-cost-container::-webkit-scrollbar-track {
    background: transparent;
  }

  .tour-detail-table-head {
    width: 192px;
  }

  .tour-details__tour-name {
    gap: 24px;
  }

  .show-conditions {
    width: 100%;
    height: 100%;
  }

  .details-about-hidden.show {
    height: 624px;
  }

  .tour-details__list-point {
    height: 16px;
    width: 8px;
  }

  .tour-details__list svg {
    height: 16px;
    width: 8px;
  }

  .reservation {
    width: 100%;
    height: 100%;
  }

  .reservation-hidden.show {
    height: 1520px;
  }

  .reservation-hidden-policy {
    width: 100%;
    height: 216px;
  }

  .tour-details__dropdown-bus1-places {
    height: 608px;
    width: 240px;
    grid-template-columns: repeat(5, 40px);
    grid-template-rows: repeat(13, 32px);
    padding: 24px 8px;
    gap: 12px 4px;
  }

  .bus-place-top-left {
    grid-template-columns: repeat(2, 40px);
    grid-template-rows: repeat(6, 32px);
    grid-column: 4 / span 2;
    grid-row: 1 / span 6;
    gap: 12px 4px;
    grid-auto-flow: column;
  }

  .bus-place-bottom {
    grid-template-columns: repeat(2, 40px);
    grid-template-rows: repeat(13, 32px);
    grid-column: 1 / span 2;
    grid-row: 1 / span 13;
    gap: 12px 4px;
    grid-auto-flow: column;
  }

  .bus-main-place {
    grid-column: 3 / span 1;
    grid-row: 13 / span 1;
    gap: 12px 4px;
  }

  .bus-place-top-right {
    grid-template-columns: repeat(2, 40px);
    grid-template-rows: repeat(5, 32px);
    grid-column: 4 / span 2;
    grid-row: 9 / span 5;
    gap: 12px 4px;
    grid-auto-flow: column;
  }

  .tour-details__dropdown-bus2-places {
    width: 240px;
    height: 100%;
    padding: 24px 16px;
  }

  .mini-bus-bottom-seats {
    width: 84px;
    grid-template-columns: repeat(2, 40px);
    grid-template-rows: repeat(5, 32px);
    grid-gap: 12px 4px;
    grid-column: 1 / span 2;
    grid-row: 1 / span 5;
    margin-top: 0;
    margin-right: 32px;
    grid-auto-flow: column;
  }

  .mini-bus-top-seats {
    width: 84px;
    grid-template-columns: repeat(2, 40px);
    grid-template-rows: repeat(4, 32px);
    grid-gap: 12px 4px;
    grid-column: 3 / span 4;
    grid-row: 2 / span 4;
    grid-auto-flow: column;
  }

  .mini-bus-top-seats .svg-seat-container:nth-child(-n + 4),
  .mini-bus-bottom-seats .svg-seat-container:nth-child(-n + 5),
  .bus-place-top-right .svg-seat-container:nth-child(-n + 5),
  .bus-place-top-left .svg-seat-container:nth-child(-n + 6),
  .bus-place-bottom .svg-seat-container:nth-child(-n + 13) {
    grid-area: auto / 2;
  }

  .mini-bus-top-seats .svg-seat-container:nth-child(n + 5),
  .bus-place-top-right .svg-seat-container:nth-child(n + 6),
  .mini-bus-bottom-seats .svg-seat-container:nth-child(n + 6),
  .bus-place-bottom .svg-seat-container:nth-child(n + 14) {
    grid-area: auto / 1;
  }

  .hotels-card > .tour-details__card-name {
    margin-bottom: 24px;
  }

  .tour-program-hidden.show {
    height: 5588px;
  }

  .tour-details__dropdown-place-info {
    flex-direction: column;
    gap: 8px;
  }

  .tour-details__dropdown-place-reserved,
  .tour-details__dropdown-place-free {
    justify-content: flex-start;
  }

  .gallery-img:first-child.active,
  .gallery-img:last-child.active,
  .gallery-img.active {
    width: 240px;
  }

  .tour-details__tour-name-btn {
    line-height: 24px;
  }

  .picture-card {
    height: 100%;
  }

  .gallery-card {
    gap: 0;
    padding: 0 0 24px 0;
  }

  .gallery-card-name {
    padding: 24px;
  }

  .tour-details__gallery-content {
    overflow: hidden;
    width: 288px;
  }

  .tour-details__hotels-cards {
    flex-direction: column;
    gap: 32px;
  }

  .price-of-evro {
    display: block;
  }

  .tour-details__btn-reserve {
    width: 190px;
  }

  .tour-details__account-reservation-dropdown-container {
    margin: 0;
  }

  .tour-details__day-descr {
    height: 100%;
  }

  .tour-details__tour-name {
    height: 100%;
  }

  .tour-details__hotel-card {
    width: 100%;
    height: 100%;
  }

  .tour-details__hotel-image {
    width: 56px;
    height: 56px;
  }

  .hotels-card {
    overflow-wrap: break-word;
  }

  .tour-details__hotel-text {
    width: 168px;
  }

  .tour-details__first-input-holder {
    width: 100%;
  }

  .tour-details__hotel-info {
    width: 100%;
    height: 100%;
  }

  .label-for-input {
    max-width: 192px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .tour-details__first-input-select,
  .tour-details__third-input-select,
  .tour-details__second-input {
    width: 100%;
    margin-bottom: 16px;
    border-radius: 16px;
  }

  .tour-details__first-input-select,
  .tour-details__second-input-select,
  .tour-details__second-input-holder,
  .tour-details__third-input-select,
  .tour-details__third-input-holder {
    width: 100%;
  }

  .tour-details__input-container {
    flex-direction: column;
    background: transparent;
    width: 100%;
    height: 100%;
    gap: 16px;
  }

  .tour-details__checkbox-container {
    height: 100%;
    padding: 0;
  }

  .tour-details__line {
    width: 100%;
  }

  .tour-details__third-input-holder > .choices > .choices__inner,
  .tour-details__second-input-holder > .choices > .choices__inner,
  .tour-details__first-input-holder > .choices > .choices__inner {
    width: 100%;
    min-width: 240px;
  }

  .choices__list--single .choices__item {
    max-width: 172px;
  }

  .feedback-card-container {
    height: 100%;
  }

  .tour-details__feedback-card {
    height: 100%;
    width: 230px;
    max-height: unset;
  }

  .tour-details__feedback-people {
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    gap: 8px;
    margin-top: 0;
    padding: 0 24px 24px 24px;
  }

  .gallery-card > .slider {
    display: flex;
    align-items: center;
    width: 288px;
    overflow: hidden;
    position: relative;
  }

  .gallery-swiper {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: start;
  }

  .gallery-img {
    width: 40px;
    flex-shrink: 0;
  }

  .gallery-img.active {
    width: 240px;
    margin: 0;
  }
  .gallery-img:first-child {
    margin-left: 24px;
  }

  .gallery-img:first-child.active {
    margin-left: 24px;
  }

  .gallery-img:last-child {
    margin-right: 24px;
  }

  .gallery-img:last-child.active {
    margin-right: 24px;
  }

  .gallery-swiper::-webkit-scrollbar {
    display: none;
  }

  .tour-details__feedback-card-top {
    width: 100%;
  }

  .tour-details__account-info {
    width: 182px;
  }

  .feedback-card-container {
    padding: 0;
  }
  .checkbox-wrapper-1 label,
  .checkbox-wrapper-2 label,
  .checkbox-wrapper-3 label,
  .checkbox-wrapper-4 label {
    line-height: 24px;
  }
}
