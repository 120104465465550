.departure-descr {
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $black;
}

.departure-group-phones {
  display: flex;
  gap: 32px;
}

.departure-group-phones__usual {
  display: flex;
  flex-direction: column;
  padding-left: 36px;
  gap: 8px;
  max-width: 254px;
  background-image: url('../../img/phone-icon.svg');
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 24px 24px;
}

.departure-group-phones__emergency {
  display: flex;
  flex-direction: column;
  padding-left: 36px;
  gap: 8px;
  background-image: url('../../img/emergency-phone-icon.svg');
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 24px 24px;
}

.departure-group-phones__number {
  font-family: "Museo Sans Cyrl 500";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: $black;
}

.departure-group-phones__work-time {
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $black;
}

table {
  border-collapse: collapse;
  margin-bottom: 64px;
}

table tr:first-child th {
  border-top: 0;
}

table tr td:first-child,
table tr th:first-child {
  border-left: 0;
}

table tr:last-child td,
table tr:last-child td {
  border-bottom: 0;
}

table tr td:last-child,
table tr th:last-child {
  border-right: 0;
}

td,
th {
  border: 1px solid $bottom-line;
  text-align: center;
  vertical-align: middle;
}

.table-head {
  padding: 24px;
  background: $color__hover-clear;
}

.table-head:first-child {
  border-top-left-radius: 24px;
}

.table-head:last-child {
  border-top-right-radius: 24px;
}

.table-cell-descr {
  background-color: $white;
}

td:first-child {
  border-bottom-left-radius: 24px;
}

td:last-child {
  border-bottom-right-radius: 24px;
}

.table-cell-descr {
  padding: 24px 16px;
}

.tour-name:not(:last-child),
.name-cell,
.programm-date:not(:last-child) {
  margin-bottom: 12px;
}

.tour-name,
.phone-number,
.programm-date {
  display: block;
  font-family: "Museo Sans Cyrl 500";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.name-cell {
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $black;
}

.phone-number,
.programm-date,
.tour-name {
  color: $button-color;
}

.programs-table {
  margin: 0 auto;
  min-width: 904px;
  margin-bottom: 64px;
}
