.important-info__tab-container {
  margin-bottom: 64px;
}

.important-info__feedback {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.important-info__feedback-card {
  max-width: none;
  max-height: none;
}

.important-info__feedback-card-descr {
  margin-bottom: 0;
  -webkit-line-clamp: initial;
}
