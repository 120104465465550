.footer {
  position:static;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-color: $footer__backgraound;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  padding-top: 56px;
  padding-bottom: 56px;
  margin-top: auto;
}

.footer__container {
  width: 1215px;
  height: 124px;
  margin: 0 auto;
}

.footer__row {
  display: grid;
  gap: 32px;
  grid-template-columns: repeat(4, auto);
}

.footer__text {
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 24px;
  color: $footer-text;
}

.footer__socials {
  display: flex;
  gap: 32px;
  align-items: center;
}

.footer__nav-wrapper {
  display: flex;
  gap: 32px;
}

.footer__nav {
  width: 280px;
  height: 120px;
  display: flex;
  gap: 20px;
}

.footer__nav-links {
  width: 279px;
  height: 124px;
  line-height: 24px;
}

.footer__nav-item {
  display: grid;
}
.footer__nav-link {
  line-height: 24px;
  width: 280px;
  margin-bottom: 24px;
}

.footer__nav-item-social {
  margin-bottom: 16px;
}

.footer__nav-list li a {
  color: $black;
  text-decoration: none;
}

.footer__nav-list > li > a:hover {
  color: $button-color;
  font-weight: 600;
}

.footer__nav-link-tg:hover svg rect {
  fill: $svg-tg;
}

.footer__nav-link-vk:hover svg rect {
  fill: $svg-vk;
}

.footer__nav-link-ok:hover svg rect {
  fill: $svg-ok;
}

.footer__nav-link-tg:hover svg rect,
.footer__nav-link-vk:hover svg rect,
.footer__nav-link-ok:hover svg rect {
  transition: fill 0.3s ease;
}

.footer__nav-link-last {
  line-height: 24px;
  height: 24px;
}

.footer__nav-item-links {
  display: flex;
  gap: 16px
}

@media screen and (max-width: 768px) {

  .footer {
    padding: 24px 0;
  }

  .footer__container {
    height: 100%;
  }

  .footer__row {
    gap: 0;
    grid-template-columns: repeat(1, auto);
  }

  .footer__nav-item {
    margin-bottom: 16px;
  }

  .footer__nav-link {
    margin-bottom: 0;
  }
}
