.about-us-section {
  background-color: $light_gray;
}

.about-us__container {
  margin: 0 auto;
  max-width: 800px;
  padding: 0;
}

.about-us__block {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  max-width: 800px;
  border-radius: 24px;
  background: $white;
  box-shadow: 0 1px 4px 0 $smoke_white_shadow, 0 24px 48px 0 $grey_shadow;
}

.about-us__block:not(:last-child) {
  margin-bottom: 24px;
}

.about-us__header {
  font-family: "Museo Sans Cyrl 500";
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  color: $black;
}

.link-more-page {
  margin: 0;
  font-family: "Museo Sans Cyrl 500";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: $button-color;
  overflow: hidden;
  text-align: right;
  text-overflow: ellipsis;
  transition: color ease .3s;
  &:hover {
    color: $text-link-hover;
  }
}

.about-us-top {
  justify-content: space-between;
  margin-bottom: 24px;
}

.about-us-middle {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 40px;
}

.about-us-bottom {
  display: flex;
  gap: 32px;
}

.about-us__descr {
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

.about-us__card {
  display: flex;
  padding: 4px;
  max-height: 242px;
  flex-direction: column;
  align-items: flex-start;
  flex-basis: 100%;
  height: 242px;
  background: $white;
  border-radius: 24px;
  box-shadow: 0 1px 4px 0 $smoke_white_shadow, 0 24px 48px 0 $grey_shadow;
}

.about-us__card-descr {
  padding: 12px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;
  color: $black;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Museo Sans Cyrl 500";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.about-us-first {
  min-height: 170px;

  width: 100%;
  background-image: url('../../img/about-us-bg-1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  border-radius: 24px;
}

.about-us-second {
  min-height: 170px;
  width: 100%;
  background-image: url('../../img/about-us-bg-2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  border-radius: 24px;
}

.about-us-third {
  min-height: 170px;
  width: 100%;
  background-image: url('../../img/about-us-bg-3.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  border-radius: 24px;
}

.about-us-fourth {
  min-height: 170px;
  width: 100%;
  background-image: url('../../img/about-us-bg-4.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  border-radius: 24px;
}

.what-is-my-group__descr {
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

.what-is-my-group__container {
  min-height: 176px;
}

.trust-your-holiday__descr-strong {
  font-family: "Museo Sans Cyrl 500";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.products-of-company__descr-block {
  display: flex;
  width: 100%;
}


@media screen and (max-width: 768px) {

  .about-us__container {
    max-width: 288px;
  }

  .about-us-top {
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    gap: 12px;
  }

  .about-us__header {
    font-size: 32px;
    line-height: 40px;
  }

  .about-us-middle {
    margin-bottom: 24px;
  }

  .about-us-bottom {
    flex-direction: column;
    gap: 16px;
  }

  .about-us__card {
    min-height: 242px;
    max-width: 100%;
  }

  .about-us__card-descr{
    margin: auto;
  }

  .about-company {
    padding: 20px 16px 40px 16px !important;
  }
}

