.page-container {
  margin: 0 auto;
  max-width: 1215px;
  display: flex;
  gap: 32px;
}

.important-info__container {
  max-width: 904px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 24px;
  box-shadow: 0 1px 4px 0 $smoke_white_shadow, 0 24px 48px 0 $grey_shadow;
  background-color: $white;
  margin-bottom: 24px;
}

.important-info__section-offset {
  padding-bottom: 24px;
}

.important-info__section-last-child {
  margin-bottom: 64px;
}

.important-info__header {
  font-family: "Museo Sans Cyrl 500";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  color: $black;
}


.important-info__header-main {
  font-family: "Museo Sans Cyrl 500";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  color: $black;
}

.important-info__descr {
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $black;
}

.important-info__tabs {
  display: flex;
  gap: 32px;
  max-width: 1215px;
  margin: 0 auto;
}

.important-info-nav > .tab-nav__desktop > .tab-btn-hover {
  cursor: pointer;
}


