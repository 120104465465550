.banner {
  width: auto;
  height: 800px;
  position: relative;
  padding-top: 72px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  z-index: 100;
}

.banner-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 10;
  margin-top: -72px;
}

.banner-slider {
  width: auto;
  height: 800px;
  overflow: hidden;
  position: relative;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
}

.banner-slide {
  min-width: 100%;
  height: 800px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.banner-sliders {
  width: auto;
  height: 800px;
}

.banner-slide-background {
  width: 100%;
  height: 800px;
  background-color: $bcg-color-index;
  position: absolute;
  top: 0;
}

.banner-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-slide-content {
  width: 1008px;
  height: 140px;
  position: absolute;
  top: 47.5%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.banner-slide-content-item {
  height: 256px;
}

.banner__header {
  height: 92px;
  margin-bottom: 16px;
  font-size: 78px;
  line-height: 86px;
  font-weight: 600;
  color: $white;
  z-index: 10;
  font-family: "Museo Sans Cyrl 700";
}

.banner__header-item {
}

.banner__paragraph {
  font-family: "Museo Sans Cyrl 500";
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  color: $white;
  margin: 0;
  z-index: 10;
}

.banner__paragraph-item {
  width: 646px;
  height: 64px;
  margin-bottom: 40px;
}

.banner__button-item {
  width: 211px;
  height: 44px;
  border-radius: 8px;
  padding: 10px 40px;
  border: none;
  background-color: $button-color;
  color: $white;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
}

.banner__button-item:active {
  background: var(--entry-hover-background, $button-focus);
}

.banner-slides {
  left: 0;
  display: flex;
  position: relative;
  transition: left 1s ease;
}

.banner-slide-img {
  width: 1800px;
}

.select {
  margin-top: auto;
  position: relative;
}

.select__header {
  position: relative;
  display: flex;
  height: 72px;
  padding: 24px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  cursor: pointer;
  min-width: 420px;
  margin: 0;
  background-color: $white;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  z-index: 100;
  transition: background-color ease 0.5s;
  &:hover {
    background-color: $button-hover;
  }
}

.select__header::after {
  content: "";
  height: 36px;
  width: 2px;
  position: absolute;
  right: 0;
  top: 25%;
  background-color: $input-spliter;
}

.select__current {
  padding: 8px;
  font-size: 16px;
  line-height: 24px;
  z-index: 100;
  font-family: "Museo Sans Cyrl 300";
}

.select__icon {
  width: 40px;
  height: 40px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-shrink: 0;
  z-index: 100;
}

.bottom {
  position: absolute;
  width: 1216px;
  height: 72px;
  margin: 40px auto;
  left: 0;
  right: 0;
}

.select__body {
  max-width: 435px;
  margin: 0;
  border-top: 0;
  z-index: 100;
}

.select__item {
  display: none;
  line-height: 24px;
  padding: 8px;
  font-size: 16px;
  cursor: pointer;
  z-index: 100;
  &:hover {
    background-color: #f2f2f2;
  }
}

.select.is-active {
  .select__item {
    display: block;
  }
}

.select__header {
  display: flex;
  z-index: 100;
}

.select__current {
  margin: 0;
  padding: 8px;
  font-size: 18px;
  line-height: 24px;
  z-index: 100;
}

.select__icon {
  width: 14px;
  height: 7px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-shrink: 0;
  z-index: 100;
}

.search-btn {
  display: flex;
  width: 72px;
  height: 72px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  background-color: $button-color;
  z-index: 100;
  border-radius: 0 16px 0 0;
  transition: background-color ease 0.3s;

  &:hover {
    background-color: $button-hover-color;
  }
}

.banner-left-select {
  min-width: 435px;
  border-radius: 16px 0 0 0;
}

.date-input {
  width: 285px;
  padding: 24px;
  transition: background-color ease 0.3s;
  border: none;
  z-index: 100;
  color: $black;
  font-family: "Museo Sans Cyrl 300";
  &:hover {
  }
}

::placeholder {
  color: $black;
}

.buttons__banner {
  width: 1008px;
  margin: -152px auto 0;
  display: flex;
  flex-direction: row;
  gap: 16px;
  z-index: 100;
}

// TODO: fix -> use -custom-position-
.index__body {
  .air-datepicker.-active-:not(.-custom-position-) {
    //width: 276px !important;
  }
}

@media screen and (max-width: 768px) {

  // FIXME: fix hard-style use
  .index__body {
    .choices[data-type*=select-one] {
      width: 320px;
      z-index: 100;
      margin-bottom: 0;
    }
  }

  .banner {
    padding-top: 0;
    margin-bottom: 264px;
    height: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .banner::before {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .banner-container {
    padding: 0;
    margin: 0 auto;
    height: auto;
    max-width: 320px;
  }

  .banner-sliders {
    height: 420px;
  }

  .banner-slider {
    height: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .banner-slide-background {
    height: 100%;
  }

  .banner-slide-content {
    width: 100%;
    height: 100%;
    padding: 142px 24px 0 24px;
    margin: 0 auto;
    text-align: center;
  }

  .banner-slide-content-item {
    padding: 120px 24px 0 24px;
  }

  .banner__header {
    margin-bottom: 8px;
    height: auto;
    font-size: 32px;
    line-height: 40px;
  }

  .banner__paragraph {
    font-size: 16px;
    line-height: 24px;
  }

  .banner__paragraph-item  {
    width: 100%;
  }

  .banner__button-item {
    width: 100%;
  }

  .buttons__banner {
    width: 100%;
    padding-top: 78px;
    justify-content: center;
    margin-top: -142px;
  }

  .bottom {
    position: relative;
    flex-direction: column;
    margin-top: 24px;
    width: 100%;
    max-height: 100%;
  }

  .select__body {
    max-width: 100%;
  }

  .select {
    border-bottom: $input-spliter solid 2px;
  }

  .select__header {
    max-width: 100%;
    min-width: 100%;
    border-radius: 0;

    &::after  {
      content: none;
    }
  }

  .select__current {
    padding: 0;
  }

  .date-input {
    width: 100%;
  }

  .search-btn {
    width: 100%;
    border-radius: 0;
    height: 44px;
    gap: 10px;
  }
}


// TODO: fix gray input borders