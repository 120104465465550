.golden-ring {
  background: $white;
}

.golden-ring-container {
  width: 1216px;
  height: 688px;
  margin: 0 auto;
}

.golden-ring__header {
  margin-bottom: 24px;
  font-family: "Museo Sans Cyrl 700";
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  color: $black;
}

.golden-ring-sliders {
  margin-top: 40px;
}

.golden-ring__descr {
  overflow: auto;
  width: 1216px;
  height: 96px;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: $black;
}

.golden-ring-carousel {
  width: 1216px;
  height: 480px;
  overflow: hidden;
  position: relative;
  border-radius: 24px;
}

.slide {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.slide-content {
  width: 1216px;
  height: 480px;
  background-color: $bcg-color-slider;
}

.slider .slides .slide img {
  object-fit: cover;
}

.slider .slides .slide .slide-content {
  display: grid;
  position: absolute;
  top: 0;
}

.slides {
  left: 0;
  display: flex;
  position: relative;
  transition: left 1s ease;
}

.slide-content-grid {
  width: 1202px;
  height: 480px;
  padding: 40px;
  display: grid;
}

.golden-ring-bottom {
  display: flex;
  width: 1136px;
  height: 344px;
  justify-content: space-between;
  z-index: 1;
}

.golden-ring-slider__header {
  font-family: "Museo Sans Cyrl 700";
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  height: 40px;
  color: $white;
  z-index: 100;
  margin-bottom: 24px;
}

.golden-ring-slider__descr-weight {
  font-family: "Museo Sans Cyrl 700";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  color: $white;
  z-index: 100;
}

.golden-ring-slider__descr {
  max-height: 256px;
  overflow: auto;
  text-overflow: ellipsis;
  font-family: "Museo Sans Cyrl 500";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  color: $white;
  z-index: 100;
}

.golden-ring-left {
  display: flex;
  margin: 0;
  width: 1216px;
  height: 168px;
}

.left-top-slider {
  width: 628px;
  height: 256px;
  margin-top: 16px;
  margin-left: 14px;
}

.golden-ring-right {
  width: 370px;
  height: 400px;
  z-index: 100;
}

.golden-ring__desktop-map {
  display: block;
}

.golden-ring__mobile-map {
  display: none;
}

.buttons {
  display: flex;
  margin: 0;
  gap: 16px;
  z-index: 100;
  margin-left: 54px;
  margin-top: -80px;
}

.btn-arrows {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid $button-color;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(2.000000238418579px);
  z-index: 100;
  transition: background-color ease 0.5s;
  &:hover {
    background-color: $button-hover;
  }
}

.btn-arrows:focus {
  outline: none;
}

.slide-92m {
  margin-right: 30px;
}

.yaroslavl-descr {
  width: 610px;
  height: 192px;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 768px) {

  .golden-ring-container {
    height: 100%;
  }

  .golden-ring-left {
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 24px;
  }

  .golden-ring-sliders {
    margin-top: 24px;
    height: 680px;
  }

  .golden-ring__header {
    font-size: 32px;
  }

  .golden-ring__descr {
    width: 100%;
    height: 100%;
  }

  .golden-ring-right {
    width: 240px;
    height: 240px;
  }

  .golden-ring__desktop-map {
    display: none;
  }

  .golden-ring__mobile-map {
    display: block;
  }

  .golden-ring-carousel {
    width: 100%;
    height: 680px;
  }

  .slide-content {
    width: 100%;
    height: 100%;
  }

  .slide-content-grid {
    height: 100%;
    padding: 0;
  }

  .golden-ring-right__mobile{
    margin: 0;
    z-index: 100;
    display: unset;
  }

  .golden-ring-bottom {
    padding: 24px;
    flex-direction: column-reverse;
    justify-content: flex-end;
    gap: 16px;
    width: 290px;
    height: 100%;
  }

  .left-top-slider {
    width: 100%;
    gap: 16px;
    margin: 0;
  }

  .golden-ring-slider__header {
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    margin-bottom: 16px;
  }

  .golden-ring-slider__descr {
    height: 264px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 11;
  }

  .buttons {
    margin-top: -64px;
    margin-left: 0;
    justify-content: center;
    gap: 24px;
  }
}
