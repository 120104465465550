.partners-info__section {
  margin: 0 auto;
  padding-top: 24px;
  padding-bottom: 64px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.partners-info__block {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.partners-info__container {
  max-width: 800px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 24px;
  background: $white;
  box-shadow: 0 1px 4px 0 $box-shadow-tabs  , 0 24px 48px 0 $box-sh-tabs;
}

.partners-info__descr {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

@media (max-width: 768px) {
  .partners-info__section {
    padding: 20px 16px 40px 16px;
  }

  .partners-info__block {
    max-width: 288px;
  }
}
