.catalogue-current-year {
  padding-top: 40px;
}

.catalogue-current-year__descr {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

//.tab-btn {
//  display: flex;
//  align-items: center;
//  gap: 8px;
//  padding: 8px 8px 8px 8px;
//  font-family: "Museo Sans Cyrl 300";
//  font-size: 16px;
//  font-style: normal;
//  font-weight: 300;
//  line-height: 24px;
//  color: $black;
//  stroke: $bottom-line;
//}

//.tab-btn-active {
//  //pointer-events: none;
//  color: black;
//  stroke: $button-color;
//  border-radius: 12px;
//  background-color: $white;
//  box-shadow: 0 1px 4px 0 $smoke_white_shadow, 0 24px 48px 0 $grey_shadow;
//}

.tab-panel:not(.tab-pane-show) {
  display: none;
}



.catalogue-of-programms {
  width: 100%;
  display: flex;
  gap: 24px;
}

.catalogues-download {
  padding: 8px 11px 8px 80px;
}

.ad-materials {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.ad-materials__descr {
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $black;
}
