.individual__account-main-container {
  width: 1216px;
  height: 592px;
  display: flex;
  justify-content: space-between;
  margin: 40px auto 60px;
}

.individual__account-container {
  width: 904px;
  display: grid;
  gap: 24px;
}

.individual__account-information {
  width: 904px;
  height: 220px;
  border-radius: 24px;
  background-color: $white;
  box-shadow: 0 1px 4px 0 $box-shadow-tabs, 0 24px 48px 0 $box-sh-tabs;
}

.individual__account-information-content {
  padding: 24px;
  display: grid;
  gap: 24px;
}

.individual__account-name {
 font-weight: 600;
 font-size: 32px;
 line-height: 40px;
}

.individual__account-frame {
  display: flex;
}

.individual__account-frame-photo {
  background-color: $button-hover;
  width: 100px;
  height: 108px;
  margin-right: 24px;
  border-radius: 24px;
}

.individual__account-frame-photo-img {
  margin-right: 24px;
  width: 100px;
  height: 108px;
  border-radius: 24px;
  padding: 3.6px;
}

.individual__account-frame-information {
  height: 108px;
  display: grid;
  gap: 16px;
}

.individual__account-birthday,  .individual__account-phone-number, .individual__account-mail {
  display: flex;
}

.individual__account-text {
  width: 156px;
  margin-right: 8px;
  line-height: 24px;
}

.individual__account-large-text {
  font-family: 'Museo Sans Cyrl 500';
  font-weight: 600;
  line-height: 24px;
}

.individual__account-passport {
  width: 904px;
  height: 272px;
  background-color: $white;
  border-radius: 24px;
  box-shadow: 0 1px 4px 0 $box-shadow-tabs, 0 24px 48px 0 $box-sh-tabs;
}

.individual__account-passport-content {
  padding: 24px;
  width: 856px;
  display: grid;
  gap: 24px;
}

.individual__account-passport-name {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.individual__account-passport-frame-information {
  display: grid;
  gap: 24px;
}

.individual__account-passport-flex {
  display: flex;
}

.individual__account-passport-text {
  width: 280px;
  margin-right: 8px;
  line-height: 24px;
  font-size: 16px;
}

.individual__account-buttons {
  margin-top: 8px;
  width: 420px;
  height: 44px;
  display: flex;
  gap: 16px;
}

.individual__account-button-first {
  width: 197px;
  height: 44px;
  border-radius: 8px;
  padding: 10px 40px;
  border: none;
  background-color: $button-color;
  color: $white;
  font-weight: 600;
  cursor: pointer;
  transition: background-color ease .3s;
}

.individual__account-button-first:active {
  background: var(--entry-hover-background, $button-focus);
}

.individual__account-button-first:hover {
  background-color: $button-hover-color;
}

.individual__account-button-second {
  width: 207px;
  height: 44px;
  border: 1px solid $button-color;
  border-radius: 8px;
  background: $white;
  color: $button-color;
  padding: 10px 40px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color ease .3s;

}

.individual__account-button-second:active {
  color: $button-focus;
  outline: none;
}

.individual__account-button-second:hover {
  background-color: $button-hover;
}

.account-frame-size {
  height: 24px;
}