.reject-tour__header {
  padding-left: 40px;
  background-image: url('../../img/cross.svg');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 24px 24px;
}

.reject-tour__descr-block {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.reject-tour__descr {
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $black;
}

.how-to-pay__section-last-child {
  margin-bottom: 64px;
}
