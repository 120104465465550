@font-face {
  font-family: "Museo Sans Cyrl 100";
  src: url('/fonts/MuseoSansCyrl-100.ttf') format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: "Museo Sans Cyrl 300";
  src: url('../fonts/MuseoSansCyrl-300.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: "Museo Sans Cyrl 500";
  src: url('../fonts/MuseoSansCyrl-500.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: "Museo Sans Cyrl 700";
  src: url('../fonts/MuseoSansCyrl-700.ttf') format('truetype');
  font-weight: 700;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: "Museo Sans Cyrl 300";
  font-style: normal;
  color: black;
  text-decoration: none;
  list-style: none;
}

p {
  margin: 0;
}

ul {
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}

img {
  width: 100%;
  height: 100%;
}

body {
  margin: 0 auto;
  min-width: 320px;
  font-family: "Museo Sans Cyrl 300";
  background: var(--color-white);
  overflow-y: scroll;
  margin-right: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.list-reset {
  padding: 0;
  list-style: none;
}

.btn-reset {
  padding: 0;
  border: none;
  cursor: pointer;
  background: none;
}

.btn-disable {
  background-color: $btn-disable !important;
  cursor: default;
  opacity: 0.5;
}

.flex {
  display: flex;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  z-index: 10;
}

menu,
ol,
ul {
  padding: 0;
}

.container {
  width: 100%;
  max-width: 1245px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

.section-offset {
  padding: 64px 0;
}

a {
  color: inherit;
  text-decoration: none;
}

.btn {
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  transition: background-color .3s ease;

  &:hover {
    background-color: $button-hover-color;
  }
}

header {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
}

body {
  background-color: $light_gray;
}

body.scroll {
  overflow: hidden;
}

.section-container {
  margin: 0 auto;
  max-width: 800px;
  padding: 24px;
  background-color: $white;
  border-radius: 24px;
  box-shadow: 0 1px 4px 0 $box-shadow-tabs , 0 24px 48px 0 $box-sh-tabs;
}

.section__header {
  font-family: "Museo Sans Cyrl 500";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  color: $black;
  word-wrap: normal;
  hyphens: auto;
}

.section-description {
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $black;
}

.header__tier-four {
  font-family: "Museo Sans Cyrl 500";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  color: $black;
}

.header__tier-three {
  font-family: "Museo Sans Cyrl 500";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  color: $black;
}

.list-item {
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $black;
}

.download-file {
  font-family: "Museo Sans Cyrl 500";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: $button-color;
  transition: color .3s ease;

  &:hover {
    color: $black;
  }
}

.link-to-download__descr {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  padding-top: 5px;
}

.link-to-download {
  display: flex;
  gap: 16px;
  align-items: center;
  fill: $button-color;
  width: 100%;
  border-radius: 24px;
  border: 1px solid $button-hover;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  transition: border-color .3s ease;

  &:hover {
    border-color: $button-color;

  }

  &:hover rect {
    fill: $button-color;
  }

  &:hover path {
    stroke: $white;
  }
}

.link-to-download rect {
  fill: #FFE4CC;
  transition: fill .3s ease;
}

.link-to-download path {
  stroke: $button-color;
  transition: stroke .3s ease;
}

.link-to-download svg {
  min-height: 64px;
  min-width: 64px;
}

.header__tier-five {
  font-family: "Museo Sans Cyrl 500";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: $black;
}

.checkbox-hidden {
  display: none !important;
}

.checkbox-visible {
  display: block;
}

body {
  scrollbar-color: $button-hover transparent;
  scrollbar-width: 100px;
}

.button {
  display: flex;
  padding: 10px 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: "Museo Sans Cyrl 500";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: $white;
  background-color: $button-color;
  border-radius: 8px;
}

.page-title {
  font-family: "Museo Sans Cyrl 700";
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
}

.title-container {
  font-family: "Museo Sans Cyrl 700";
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.background {
  background-color: $white;
  box-shadow: 0 1px 4px 0 $box-shadow-tabs, 0 24px 48px 0 $box-sh-tabs;
  border-radius: 24px;
  padding: 24px;
}

.gray-text {
  color: $gray;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

.common-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

.bold-text {
  font-family: 'Museo Sans Cyrl 500';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.choices__list--dropdown .choices__list {
  max-height: unset;
}

@media screen and (max-width: 768px) {

  .section-offset {
    padding: 32px 0;
  }

  .container {
    max-width: 288px;
  }

  .section-container {
    max-width: 288px;
    padding: 20px 16px 40px 16px;
  }

  .link-to-download {
    text-overflow: ellipsis;
    max-height: 64px;
    overflow: hidden;
    max-width: 240px;
  }
}
