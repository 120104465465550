.reservation__container {
  width: 800px;
  padding: 0 0 64px 0;
}

.reservation__button-back-arrow {
  width: 24px;
  height: 24px;
}

.reservation__button-back-text,
.reservation__result-link-text,
.reservation__button-add-tourist,
.reservation__button-delete-tourist {
  font-family: "Museo Sans Cyrl 500";
  font-weight: 600;
  color: $button-color;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
}

.reservation__button-back {
  width: 83px;
  height: 24px;
  line-height: 24px;
  display: flex;
  gap: 12px;
  margin: 24px 0;
  align-items: center;
}

.reservation__input-main-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.reservation__info-container {
  display: flex;
  gap: 30px;
}

.reservation__info-agent,
.reservation__info-legal {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.reservation__amount-paid {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.reservation__info-agent {
  width: 371px;
}

.reservation__info-legal {
  width: 351px;
}

.reservation__input-container {
  position: relative;
  background-color: $input-color;
  width: 752px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reservation__first-input,
.reservation__second-input {
  width: 375px;
}

.reservation__date-container {
  display: flex;
  align-items: center;
  gap: 32px;
}

.reservation__date-duration {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.reservation__second-input-holder > .choices > .choices__inner,
.reservation__first-input-holder > .choices > .choices__inner {
  position: relative;
  width: 375px;
  padding: 36px 48px 12px 24px;
  border-radius: 12px;
  background-color: $input-color;
  border: 2px solid $input-color;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  transition: background-color 0.3s ease;
  z-index: 100;

  &:hover {
    background-color: $button-hover;
  }
}

.reservation__second-input-holder > .is-focused.choices > .choices__inner,
.reservation__first-input-holder > .is-focused.choices > .choices__inner {
  border-color: $button-color;
  background-color: $button-hover;
}

.reservation__first-input-holder > .choices > .choices__inner > .choices__list--single .choices__item {
  padding: 0;
}

.reservation__tourist-container {
  display: flex;
  flex-direction: column;
  gap: 70px;
}

.reservation__tourist {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.reservation__details {
  padding: 16px 24px;
  border-radius: 16px;
  border: 1px solid rgba(255, 118, 0, 0.5);
}

.reservation__search-tour-form {
  width: 375px !important;
  position: relative;
  background-color: $input-color;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reservation__search-tour-form .custom-date {
  width: 375px !important;
  padding: 24px;
}

.reservation__search-tour-form .beginning-date-of-tour {
  background-position: 324px !important;
}

.reservation__first-input-holder {
  width: 375px;
}

.passport-input {
  width: 100%;
  height: 72px;
  padding: 24px;
  background-color: $input-color;
  border: 2px solid $input-color;
  border-radius: 12px;
  transition: background-color 0.3s ease;
}

.input-holder input.active {
  padding: 36px 24px 12px 24px;
}

.input-holder textarea.active {
  padding: 36px 24px 12px 24px;
}

.input-holder select.active + label,
.input-holder select:focus + label,
.input-holder select:not([value=""]) + label {
  position: absolute;
  left: 24px;
  top: 12px;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $gray;
  z-index: 102;
}

.input-holder select.active,
.input-holder select:focus,
.input-holder select:not([value=""]) {
  padding: 36px 24px 12px 24px;
}

.reservation__add-tourist,
.reservation__button-container {
  display: flex;
  gap: 16px;
}

.reservation__add-tourist {
  cursor: pointer;
  width: 416px;
}

.reservation__button-add-tourist,
.reservation__button-delete-tourist {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.reservation__tourist-heading {
  display: flex;
  justify-content: space-between;
}

.phone-input-placeholder {
  left: 401px;
}

.reservation__bus-content {
  width: 752px;
  height: 256px;
  padding: 24px 40px;
  border-radius: 24px;
  border: 2px solid var(--border-color, $inactive-icon);
  display: grid;
  grid-template-columns: repeat(13, 40px);
  grid-template-rows: repeat(5, 32px);
  grid-gap: 12px;
}

.reservation__bus-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.reservation__place-info {
  display: flex;
  justify-content: center;
  gap: 34px;
}

.reservation__field {
  position: relative;
}

.reservation__checkbox-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.reservation__exit-button {
  display: flex;
  width: 130px;
  height: 44px;
  padding: 10px 40px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--border-color, $button-color);
  background: var(--background-color, $white);
  color: $button-color;
  font-family: "Museo Sans Cyrl 500";
  font-weight: 600;
  cursor: pointer;
}

.reservation__exit-button:hover {
  background: var(--background-color, $button-hover);
  transition: background-color 0.3s ease;
}

.reservation__place-reserved,
.reservation__place-free {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.reservation__field .note-input {
  overflow-y: hidden;
  resize: none;
}

.reservation__label-date {
  z-index: 1005 !important;
}

.reservation__search-tour-form .birthday-date {
  z-index: 998 !important;
}

.reservation__search-tour-form .custom-date:focus {
  z-index: 1000 !important;
}

.reservation__body {
  .air-datepicker {
    width: 371px !important;
    z-index: 999 !important;
    border-top: none !important;
  }

  .air-datepicker.-active-:not(.-custom-position-) {
    width: 371px !important;
  }
  .air-datepicker--navigation {
    width: 371px !important;
  }
  .air-datepicker--content {
    width: 360px !important;
  }
}

.input-placeholder-tour-type > .choices::before {
  position: absolute;
  content: "Выберите вид тура";
  left: 26px;
  top: 26px;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $black;
  background-color: var(--primary-light);
  z-index: 101;
}

.input-placeholder-tour > .choices::before {
  position: absolute;
  content: "Выберите тур";
  left: 26px;
  top: 26px;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $black;
  background-color: var(--primary-light);
  z-index: 101;
}

.input-hotel-placeholder > .choices::before {
  position: absolute;
  content: "Гостиница";
  left: 26px;
  top: 26px;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $black;
  background-color: var(--primary-light);
  z-index: 101;
}

.input-hotel-placeholder > .choices::before {
  position: absolute;
  content: "Гостиница";
  left: 26px;
  top: 26px;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $black;
  background-color: var(--primary-light);
  z-index: 101;
}

.tourliving-placeholder > .choices::before {
  position: absolute;
  content: "Проживание";
  left: 26px;
  top: 26px;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $black;
  background-color: var(--primary-light);
  z-index: 101;
}

.input-placeholder-tour-type > .choices.is-open::before,
.input-placeholder-tour > .choices.is-open::before,
.input-hotel-placeholder > .choices.is-open::before,
.tourliving-placeholder > .choices.is-open::before,
.input-placeholder-tour-type.has-value > .choices::before,
.input-placeholder-tour.has-value > .choices::before,
.input-hotel-placeholder.has-value > .choices::before,
.tourliving-placeholder.has-value > .choices::before {
  top: 12px;
  color: $gray;
}

.checkbox-wrapper-1 svg,
.checkbox-wrapper-2 svg,
.checkbox-wrapper-3 svg,
.checkbox-wrapper-4 svg {
  left: calc(var(--size) * 0.17);
}

.checkbox-wrapper-4 {
  --size: 28px;
  position: relative;
  display: flex;
  align-items: center;
}

.reservation__body {
  .air-datepicker-body--day-name {
    font-family: "Museo Sans Cyrl 300" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 300 !important;
    line-height: 24px !important;
    color: #8e8888 !important;
    width: auto !important;
    height: auto !important;
    text-transform: lowercase !important;
  }

  .air-datepicker.-active-:not(.-custom-position-) {
    width: 371px !important;
    //width: 245.33px !important;
  }

  .air-datepicker--navigation {
    width: 371px !important;
    //width: 245.33px !important;
  }

  .air-datepicker--content {
    width: 356px !important;
    //width: 230.33px !important;
  }

  .air-datepicker-cell.-disabled- {
    cursor: not-allowed;
    color: $black;
  }

  .air-datepicker-cell.empty:hover {
    color: unset !important;
    background: unset !important;
  }

  .air-datepicker {
    z-index: 100 !important;
  }

  .air-datepicker-cell.-day-::after {
    content: '';
    width: 4px;
    height: 4px;
    position: absolute;
    top: 28px;
  }

  .empty {
    cursor: not-allowed;
  }

  .free {
    cursor: pointer;
  }

  .free::after {
    background-image: url(../../img/indicator-free.svg);
  }

  .part-time {
    cursor: pointer;
  }

  .part-time::after {
    background-image: url(../../img/indicator-part-time.svg);
  }

  .occupied {
    color: unset !important;
    background: unset !important;
    cursor: not-allowed;
  }

  .occupied::after {
    background-image: url(../../img/indicator-occupied.svg);
  }
}

@media screen and (max-width: 768px) {
  .reservation__container {
    width: 288px;
    padding-bottom: 48px;
  }

  .reservation__button-back {
    margin-top: 20px;
  }

  .reservation__info-container {
    flex-direction: column;
    gap: 24px;
  }

  .reservation__info-agent,
  .reservation__info-legal {
    width: 100%;
  }

  .reservation__input-container {
    flex-direction: column;
    width: 100%;
    gap: 16px;
    background-color: $white;
  }

  .reservation__svg-icon-input {
    display: none;
  }

  .reservation__first-input-tour-type,
  .reservation__first-input-holder,
  .reservation__second-input {
    width: 100%;
  }

  .reservation__second-input-holder > .choices > .choices__inner,
  .reservation__first-input-holder > .choices > .choices__inner {
    width: 100%;
  }

  .reservation__date-container {
    width: 100%;
    flex-direction: column;
    gap: 16px;
  }

  .reservation__search-tour-form {
    width: 100% !important;
  }

  .reservation__search-tour-form .custom-date {
    width: 100% !important;
  }

  .reservation__search-tour-form .custom-date:focus {
    z-index: 1004 !important;
  }

  .reservation__body {
    .air-datepicker {
      width: 240px !important;
      margin-top: -30px !important;
      z-index: 1003 !important;
    }

    .air-datepicker.-active-:not(.-custom-position-) {
      width: 236px !important;
      z-index: 1003 !important;
    }
    .air-datepicker--navigation {
      width: 230px !important;
      z-index: 1003 !important;
    }
    .air-datepicker--content {
      width: 218px !important;
      z-index: 1003 !important;
    }
    .air-datepicker-body--day-name {
      width: 100% !important;
    }
  }

  .reservation__first-input-hotel,
  .reservation__first-input {
    width: 100%;
  }

  .reservation__add-tourist {
    width: 100%;
    align-items: center;
  }

  .reservation__container-line {
    width: 100%;
  }
  .reservation__bus-content {
    width: 100%;
    height: 100%;
    grid-template-columns: repeat(5, 40px);
    grid-template-rows: repeat(13, 32px);
    padding: 24px 8px;
    gap: 12px 4px;
  }

  .reservation__place-info {
    flex-direction: column;
    gap: 8px;
  }

  .reservation__place-reserved,
  .reservation__place-free {
    justify-content: flex-start;
  }

  .phone-input-placeholder {
    position: absolute;
    left: 26px !important;
    top: 100px !important;
  }
  .checkbox-wrapper-1 svg,
  .checkbox-wrapper-2 svg,
  .checkbox-wrapper-3 svg {
    left: calc(var(--size) * 0.18);
  }

  .checkbox-wrapper-4 svg {
    left: calc(var(--size) * 0.18);
  }

  .checkbox-wrapper-4 label:before {
    margin-bottom: 40px;
  }

  .reservation__first-input label {
    overflow: visible;
  }

  .reservation__button-container {
    flex-direction: column;
  }

  .reservation__exit-button {
    width: 100%;
  }

  .reservation__tourist-heading {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
}
