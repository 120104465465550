.how-to-pay__header {
  font-family: "Museo Sans Cyrl 500";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  color: $black;
}

.sbp__steps {
  display: flex;
  gap: 22px;
  padding-left: 18px;
}

.ordered-list {
    display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 588px;
  list-style: numbers;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

.mail-link {
  font-family: "Museo Sans Cyrl 500";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: $button-color;
  transition: color ease .3s;
  &:hover {
    color: $text-link-hover;
  }
}

.ordered-list__item {
  max-width: 608px;
  padding-left: 3px;
  font-family: "Museo Sans Cyrl 500";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  list-style: decimal;
  color: $button-color;
}

.ordered-list__item-descr {
  max-width: 588px;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $black;
  letter-spacing: -1px;
}

.qr-code {
  align-self: flex-end;
}

.sbp-qr-code {
  max-width: none;
}
