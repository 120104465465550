.news {
  background-color: $light_gray;
}

.news-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.news__header {
  font-family: "Museo Sans Cyrl 500";
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  color: $black;
}

.header-block, .btn-block {
  margin: 0;
}

.news-links {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
}

.news-card {
  display: flex;
  justify-content: space-between;
  position: relative;
  max-width: 592px;
  max-height: 128px;
  margin: 0;
  padding: 4px;
  border-radius: 24px;
  background: $white;
  transition: transform ease 2s;
  width: calc((100% - 32px) / 2);
}

.news-card:hover .news-read-more {
    transform: translateY(-4px) translateX(4px);
    & rect{
      fill: $button-hover;
    }
}

.news-img {
  margin: 0;
}

.news-img-mobile {
  display: none;
}

.news-img-descr {
  border-radius: 24px;
  max-height: 100%;
}

.card-descr {
  max-width: 392px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px 8px 8px 16px;
  margin: 0;
}

.news-descr {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  font-family: "Museo Sans Cyrl 500";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: $black;
  overflow: hidden;
  text-overflow: ellipsis;
}

.news-date {
  display: flex;
  align-items: center;
  font-family: "Museo Sans Cyrl 300";
  gap: 12px;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $black;
}

.news-read-more {
  padding-top: 8px;
  padding-right: 8px;
  margin: 0;
  transition: transform ease .3s;
}

.date-icon {
  margin: 0;
}

@media screen and (max-width: 768px) {

  .news {
    padding-bottom: 48px;
  }

  .news-container {
    gap: 24px;
  }

  .news__header {
    font-size: 32px;
    line-height: 40px;
  }

  .news-links {
    flex-wrap: nowrap;
    flex-direction: column;
    gap: 16px;
  }

  .news-card {
    flex-direction: column;
    width: 100%;
    max-height: none;
  }

  .news-img {
    display: none;
  }

  .news-img-mobile {
    display: unset;
  }

  .card-descr {
    gap: 4px;
  }

  .news-read-more {
    display: none;
  }
}
