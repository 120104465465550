.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 64px;
  padding-top: 40px;
}

.login__container {
  background-color: $white;
  border-radius: 24px;
  width: 800px;
  height: 348px;
  box-shadow: 0 1px 4px 0 $box-shadow-tabs, 0 24px 48px 0 $box-sh-tabs;
}

.login__header-container {
  width: 800px;
  height: 276px;
  padding: 24px;
  padding-bottom: 32px;
  background-color: $white;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}

.login__title-container {
  margin-bottom: 24px;
  font-weight: 600;
}

.login__title {
  font-family: "Museo Sans Cyrl 700";
  font-size: 32px;
  line-height: 40px;
}

.login__input-group {
  position: relative;
  height: 72px;
  margin-bottom: 40px;
  display: flex;
  width: 100%;
  background-color: $input-color;
  border-radius: 16px;
}

.login__input-group input {
  flex: 1;
  padding: 24px;
  background-color: $input-color;
  border: 2px solid $input-color;
  border-radius: 12px;
  transition: background-color .3s ease;
  color: $black;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;

  &:hover {
    background-color: $button-hover;
    border-color: $button-hover;
  }

  &:focus {
    background-color: $button-hover;
    border-color: $button-color;
  }
}

.password-holder,
.username-holder{
  cursor: pointer;
}

.login__svg-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.login__input-group input:focus {
  outline: none;
}

.login__buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.login__entry-button {
  width: 128px;
  height: 44px;
  display: flex;
  padding: 10px 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--entry-background, $button-color);
  color: $white;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  border: none;
  transition: background-color .3s ease;
}

.login__entry-button:focus {
  outline: none;
}

.login__entry-button:active {
  background: var(--entry-hover-background, $button-focus);
}

.login__entry-button:hover {
  background-color: $button-hover-color;
}

.login__reset-password-button {
  display: flex;
  width: 201px;
  height: 46px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid $button-color;
  border-radius: 8px;
  padding: 10px 32px;
  background: $white;
  color: $button-color;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
  transition: background-color .3s ease;
}

.login__reset-password-button:hover {
  background-color: $button-hover;
}

.login__reset-password-button:focus {
  outline: none;
  color: $button-focus;
}

.login__footer {
  display: flex;
  width: 800px;
  height: 73px;
  padding: 24px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  border-top: 1px solid var(--border-color, $button-hover);
  background: var(--background-color, $color__hover-clear);
}

.login__footer-noaccount {
  width: 106px;
  line-height: 24px;
  cursor: pointer;
}

.login__footer-orange-create,
.login__footer-orange-reg {
  line-height: 24px;
  margin-left: 32px;
  color: $button-color;
  font-weight: 600;
  cursor: pointer;
}

.login__footer-orange-create {
  width: 128px;
}

.login__footer-orange-reg {
  width: 216px;
}

@media screen and (max-width: 768px) {
  .login {
    height: 648px;
    padding: 24px 16px 48px 16px;
  }
  .login__container {
    width: 288px;
    height: 576px;
  }
  .login__header-container {
    width: 288px;
    height: 424px;
    padding: 24px 24px 32px 24px;
    display: flex;
    flex-direction: column;
  }

  .login__input-group {
    width: 240px;
    height: 160px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .login__svg-icon {
    display: none;
  }

  .login__input-group input {
    border-radius: 16px;
  }
  .login__buttons {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .login__entry-button,
  .login__reset-password-button {
    width: 240px;
    height: 44px;
  }
  .login__footer {
    width: 288px;
    height: 152px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
  }
  .login__footer-orange-create,
  .login__footer-orange-reg {
    margin: 0;
  }
}
