.discounts__section {
  padding-top: 24px ;
  margin: 0 auto;
}

.discounts__container {
  padding: 24px;
  max-width: 800px;
  background-color: $white;
  border-radius: 24px;
  box-shadow: 0 1px 4px 0 $box-shadow-tabs  , 0 24px 48px 0 $box-sh-tabs;
}

.discounts-block {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.discounts__descr {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

@media (max-width: 768px) {
  .discounts__section {
      padding: 20px 16px 48px 16px;
  }

  .discounts__container {
    max-width: 288px;
  }
}
