.fin-guarantees__section {
  padding-top: 24px;
}

.fin-guarantees__container {
  margin: 0 auto;
  flex-direction: column;
  gap: 24px;
  border-radius: 24px;
  box-shadow: 0 1px 4px 0 $box-shadow-tabs  , 0 24px 48px 0 $box-sh-tabs;
}

.fin-guarantees__descr {
  flex-direction: column;
  gap: 24px;
}

.fin-guarantees__description {
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $black;
}

.fin-guarantees__img {
  max-width: fit-content;
}

@media (max-width: 768px) {
  .fin-guarantees__section {
      padding: 20px 16px 48px 16px;
  }
}
