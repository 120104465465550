.feedback {
  background-color: $white;
}

.feedback-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.feedback-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.feedback-bottom {
  position: relative;
  display: flex;
  gap: 32px;
}

.feedback__header {
  font-family: "Museo Sans Cyrl 500";
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  color: $black;
}

.new-feedback {
  margin: 0;
  color: $button-color;
  font-family: "Museo Sans Cyrl 500";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color ease .3s;
  &:hover {
    color: $text-link-hover;
  }
}

.feedback-card {
  display: flex;
  max-width: 384px;
  max-height: 268px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border: 4px solid $button-hover;
  border-radius: 24px;
  gap: 24px;
}

.feedback-card-top {
  display: flex;
  gap: 16px;
  margin: 0;
}

.account-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  gap: 8px;
}

.account-info-img {
  width: 64px;
  height: 64px;
}

.account-img {
  width: 64px;
  height: 64px;
  background: $light__purple;
  border-radius: 12.8px;
}

.account-name {
  max-width: 255px;
  max-height: 24px;
  font-family: "Museo Sans Cyrl 500";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $black;
}

.feedback-date {
  max-width: 255px;
  max-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  background-image: url('../../img/date-icon.svg');
  background-repeat: no-repeat;
  padding-left: 36px;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $black;
  align-items: flex-end;
}

.feedback-card__descr {
  display: -webkit-box;
  margin-bottom: 12px;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $black;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  text-overflow: ellipsis;
}

.feedback__more {
  margin: 0;
  font-family: "Museo Sans Cyrl 500";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $button-color;
  transition: color ease .3s;
  &:hover {
   color: $text-link-hover;
  }
}

.feedback-watch-all {
  margin: 0;
}

.btn-watch-all {
  padding: 10px 40px;
  background-color: $button-color;
  border-radius: 8px;
  color: $white;
  transition: background-color ease .3s;
  &:hover {
    background-color: $button-hover-color;
  }
}

.feedback-slider-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 40%;
  right: -2%;
  padding: 12px;
  border: 4px solid $button-hover;
  background: $white;
  border-radius: 8px;
}

.feedback-slider-btn:hover rect {
  fill: #FBF6F1;
  border-color: $button-hover;
}

@media screen and (max-width: 768px) {

  .feedback-container {
    gap: 24px;
  }

  .feedback-top {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .feedback-bottom {
    overflow: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    gap: 8px;
  }

  .feedback-card {
    max-width: 270px;
    padding: 20px;
    max-height: none;
  }

  .feedback-card-top {
    flex-direction: column;
  }

  .feedback__header {
    font-size: 32px;
    line-height: 40px;
  }

  .feedback-slider-btn {
    display: none;
  }

  .btn-watch-all {
    width: 100%;
  }
}
