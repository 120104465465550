.common-tours__section-offset {
  padding-top: 40px;
  padding-bottom: 64px;
}

.common-tours__tabs {
  width: 100%;
  max-width: 1215px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  gap: 32px;
}

.common-tours__container {
  padding: 24px;
  display: flex;
  flex-direction: column;
  width: 904px;
  background-color: $white;
  border-radius: 24px;
  box-shadow: 0 1px 4px 0 rgba(235, 233, 232, 0.73), 0 24px 48px 0 rgba(230, 235, 245, 0.40);
  margin-bottom: 40px;
}

.type-tour-nav{
  width: 100%;
}

.tab-content {
  width: 100%;
}

.search-field {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #F5F7FA;
  border-radius: 16px;
}

.tour-search-form {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.search-tour-form {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 285px;
  height: 72px;
}

.search-tour-form-length > .choices > .choices__inner {
  position: relative;
  border-radius: 12px;
  background-color: $input-color;
  border: 2px solid $input-color;
  transition: background-color ease .3s;
  z-index: 100;

  &:hover {
    background-color: $button-hover;
    border-color: $button-hover;
  }
}

.search-tour-form-length>.is-focused.choices>.choices__inner,
.search-tour-form-length>.is-open.choices>.choices__inner {
  border-color: $button-color;
  background-color: $button-hover;
}

.search-tour-form-length > .choices > .choices__inner::before {
  position: absolute;
  content: 'Продолжительность';
  left: 22px;
  top: 12px;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: #8E8888;
  background-color: var(--primary-light);
  z-index: 101;
}

.search-tour-form-length > .choices > .choices__list--dropdown > .choices__list{
  width: 100%;
  padding: 16px 6px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.search-tour-form-length > .choices > .choices__list--dropdown> .choices__list > .choices__item {
  width: 34px;
  height: 28px;
  padding: 2px 4px;
  margin: 0 2px;
  text-align: center;
}

.date-of-tour-beginning {
  display: flex;
  flex-direction: column;
}

.common-tours__header {
  margin-bottom: 24px;
  font-family: "Museo Sans Cyrl 500";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
}

.btns {
  display: flex;
  gap: 16px;
}

.btn-to-reset {
  background-color: $white;
  color: $button-color;
  border: 1px solid $button-color;
}

.btn-to-reset:hover {
  background-color: $button-hover;
}

.results-of-search {
  width: 904px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.results-of-search__descr {
  display: flex;
  justify-content: space-between;
}

.results-of-search__header {
  font-family: "Museo Sans Cyrl 500";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

.numbers-of-items-found {
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: #8E8888;
}

.tour-card {
  position: relative;
  display: flex;
  gap: 24px;
  background: $white;
  border-radius: 24px;
  box-shadow: 0 1px 4px 0 rgba(235, 233, 232, 0.73), 0 24px 48px 0 rgba(230, 235, 245, 0.40);
}

.tour-card:hover .read-more-about-tour {
  transform: translateY(-4px) translateX(4px);

  & rect {
    fill: #FFE4CC;
  }
}

.tour-img {
  width: 230px;
  height: 208px;
  display: flex;
  padding: 4px;
}

.tour-img img {
  border-radius: 20px;
  max-width: fit-content;
}

.tour-card-info {
  max-width: 590px;
  padding-top: 24px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.tour-card-info__descr {
  max-width: 570px;
}

.card__header {
  font-family: "Museo Sans Cyrl 500";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 8px;
}

.card__descr {
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

.card__seats {
  margin-bottom: 8px;
  padding-left: 36px;
  background-image: url('../../img/seats-icon.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

.card__dates {
  padding-left: 36px;
  background-image: url('../../img/dates-icon.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

.read-more-about-tour {
  padding-top: 16px;
  padding-right: 16px;
  justify-self: flex-end;
}

.price-of-tour {
  color: $white;
  font-family: "Museo Sans Cyrl 500";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  z-index: 100;
}

.price-block {
  padding-top: 25px;
  padding-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 194px;
  height: 72px;
  position: absolute;
  right: 0;
  bottom: 0;
  background-image: url('../../img/price-of-tour.svg');
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;
}

.search-field>.choices {
  position: relative;
  width: 285px;
  height: 72px;
  margin: 0;
  z-index: 100;
}

.search-field>.choices::before {
  position: absolute;
  content: 'Город';
  left: 24px;
  top: 12px;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: #8E8888;
  background-color: var(--primary-light);
  z-index: 101;
}

.search-field>.choices>.choices__inner {
  position: relative;
  height: 72px;
  width: 100%;
  padding: 36px 24px 12px 22px;
  background-color: #F5F7FA;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $black;
  border: 2px solid $input-color;
  border-radius: 12px;
  transition: background-color .3s ease;
  z-index: 100;

  &:hover {
    background-color: $button-hover;
  }
}

.search-field>.is-focused.choices>.choices__inner,
.search-field>.is-open.choices>.choices__inner {
  border-color: $button-color;
  background-color: $button-hover;
}

.choices__list--single {
  padding: 0;
}

.search-field>.choices>.choices[data-type*=select-one] .choices__inner {
  padding: 0;
}

.custom-date {
  width: 285px;
  position: relative;
  height: 72px;
  padding: 45px 22px 10px;
  background-color: #F5F7FA;
  z-index: 101;
  transition: background-color .3s ease;
  border: 2px solid $input-color;
  border-radius: 12px;

  &:hover {
    background-color: $button-hover;
  }

  &:focus-visible {
    outline: none;
    background-color: $button-hover;
    border-color: $button-color;
  }

  &:focus {
    background-color: $button-hover;
    border-color: $button-color;
  }
}

.custom-date:not(.has-value):before {
  position: absolute;
  left: 24px;
  top: 12px;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $grey;
  background: var(--primary-light);
  content: 'Продолжительность';
}

.beginning-date-of-tour {
  background-image: url('../../img/calendar.svg');
  background-repeat: no-repeat;
  background-position: 237px;
  cursor: pointer;
}

.beginning-date-of-tour::before {
  position: absolute;
  content: 'Дата начала тура';
  left: 24px;
  top: 12px;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $grey;
  background: var(--primary-light);
  z-index: 101;
}

.no-placeholder:before {
  content: '' !important;
}

.choices__item,
.choices__placeholder {
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $black;
}

.length-of-tour {
  border-radius: 0 16px 16px 0;
}

.label-for-town {
  position: absolute;
  left: 24px;
  top: 12px;
  z-index: 101;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $grey;
}

.choices__list--dropdown .choices__item--selectable.is-highlighted {
  background-color: $button-hover;
}

.choices__list--dropdown {
  top: calc(100% - 20px);
  padding-top: 20px;
  z-index: 50;
}

.choices[data-type*=select-one].is-open:after {
  top: 60%;
  border: 2px solid $button-color;
  border-width: 2px 2px 0 0;
}

.is-flipped.is-open .choices__inner {
  border-radius: 12px;
}

.is-focused .choices__list--dropdown,
.is-active .choices__list--dropdown {
  border-color: $button-color;
  border: 2px solid $button-color;
  border-radius: 12px;
}

.is-focused .choices__inner {
  border-color: $button-color;
  border: 2px solid $button-color;
  border-radius: 0;
}

.is-open .choices__inner {
  border-color: $button-color;
  border: 2px solid $button-color;
  border-radius: 12px;
}

.search-field > .choices > .choices__inner > .choices__list--dropdown .choices__item {
  padding: 12px 16px;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}

.choices__list--dropdown .choices__item--selectable {
  background-color: $white;
}

.choices__item .choices__item--selectable {
  padding-right: 24px;
}

.choices__list--dropdown .choices__list {
  z-index: 100;
  overflow: unset;
}

.choices__list--dropdown .choices__item--selectable {
  transition: background-color .3s ease;
}

.datepicker--nav {
  width: 100%;
  justify-content: space-between;
}

.air-datepicker {
  width: 500px;
}

.air-datepicker-cell.-in-range- {
  background-color: $button-hover !important;
}

.label-for-start-date {
  position: absolute;
  left: 24px;
  top: 12px;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: $grey;
  z-index: 102;
}

.range-days {
  display: block;
  padding-left: 48px !important;
  background-image: url('/img/range-days.svg') !important;
  background-repeat: no-repeat !important;
  background-position: left !important;
  z-index: 103 !important;
}

.air-datepicker-buttons {
  display: flex !important;
  padding: 12px 16px !important;
}

.air-datepicker--buttons {
  padding: 0 !important;
}

.search-tour-form > .choices >.choices__inner > .choices__list {
  display: flex;
  flex-wrap: wrap;
}

.search-tour-form > .choices >.choices__inner  {
  width: 100%;
}

@media screen and (max-width: 768px) {

  .common-tours__section-offset {
    padding-bottom: 40px;
  }

  .common-tours__container {
    margin-bottom: 24px;
    width: 100%;
  }

  .tour-search-form {
    gap: 24px;
  }

  .common-tours__tabs {
    flex-direction: column;
  }

  .search-field {
    gap: 16px;
  }

  .search-field {
    flex-direction: column;
    background-color: transparent;
  }

  .results-of-search {
    width: 100%;
  }

  .search-field > .choices {
    width: 100%;
  }

  .search-field > .choices > .choices__inner {
    border-radius: 16px;
  }

  .search-tour-form {
    width: 100%;
  }

  .custom-date {
    width: 100%;
    border-radius: 16px;
  }

  .search-tour-form > .choices > .choices__inner {
    border-radius: 16px;
  }

  .btns {
    flex-direction: column;
  }

  .results-of-search__descr {
    flex-direction: column;
    gap: 12px;
  }

  .tour-card {
    flex-direction: column;
    gap: 0;
  }

  .tour-img {
    width: 289px;
  }

  .tour-card-info {
    padding: 24px;
  }

  .tour-img img {
    max-width: none;
    width: 100%;
    border-radius: 25px;
  }

  .card__dates {
    background-size: unset;
    background-position: unset;
  }

  .price-block {
    position: absolute;
    top: 132px;
    right: 5px;
  }
}
