.agency__account-main-container {
  width: 1216px;
  display: flex;
  gap: 32px;
  margin: 40px auto 64px;
}

.agency__account-container {
  width: 904px;
  height: 548px;
}

.agency__account-information {
  display: grid;
  gap: 24px;
  background-color: $white;
  padding: 24px;
  margin-bottom: 32px;
  border-radius: 24px;
  box-shadow: 0 1px 4px 0 $box-shadow-tabs, 0 24px 48px 0 $box-sh-tabs;
}

.agency__account-information-name {
  width: 856px;
  font-family: "Museo Sans Cyrl 500";
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
}

.agency__account-information-line {
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 24px;
}

.agency__account-information-line-text {
  width: 280px;
  align-items: center;
  line-height: 24px;
}

.agency__account-information-line-data {
  font-family: "Museo Sans Cyrl 500";
  font-weight: 600;
  align-items: center;
  line-height: 24px;
}

.agency__account-buttons {
  width: 420px;
  height: 44px;
  display: flex;
  gap: 16px;
}

.agency__account-button-first {
  width: 197px;
  height: 44px;
  border-radius: 8px;
  padding: 10px 40px;
  border: none;
  background-color: $button-color;
  color: $white;
  font-weight: 600;
  cursor: pointer;
  transition: background-color ease .3s;
}

.agency__account-button-first:hover {
  background-color: $button-hover-color;
}

.agency__account-button-first:active {
  background: var(--entry-hover-background, $button-focus);
}

.agency__account-button-second {
  width: 207px;
  height: 44px;
  border: 1px solid $button-color;
  border-radius: 8px;
  background: $white;
  color: $button-color;
  padding: 10px 40px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color ease .3s;
}

.agency__account-button-second:active {
  color: $button-focus;
  outline: none;
}

.agency__account-button-second:hover {
  background-color: $button-hover;
}

.agency__account-reservation-container {
  width: 904px;
  height: 444px;
  padding: 24px;
  border-radius: 24px;
  background-color: $white;
  display: grid;
  gap: 24px;
  box-shadow: 0 1px 4px 0 $box-shadow-tabs, 0 24px 48px 0 $box-sh-tabs;
}

.agency__account-reservation-choice {
  width: 856px;
  height: 40px;
  display: flex;
  gap: 24px;
  align-self: stretch;
}

.agency__account-reservation-choice-name {
  font-family: "Museo Sans Cyrl 500";
  font-weight: 600;
  width: 632px;
  font-size: 32px;
  line-height: 40px;
}

h1.agency__account-reservation-choice-name {
  margin: 0;
}

.agency__account-reservation-choice-buttons {
  width: 200px;
  height: 24px;
  display: flex;
  align-items: baseline;
  gap: 24px;
  margin-top: 16px;
}

.orng-text {
  font-family: "Museo Sans Cyrl 700";
  color: $button-color;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
}

.agency__account-reservation-dropdown-text-1, .agency__account-reservation-dropdown-text-2 {
  line-height: 24px;
}

.agency__account-reservation-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color .3s ease;
  background-color: $input-color;
  border-radius: 16px;
}

.agency__account-reservation-search-container {
  width: 100%;
  height: 100%;
  padding: 24px 50px 24px 24px;
  outline: none;
  border: 2px solid $input-color;
  border-radius: 16px;
  background-color: $input-color;
  transition: background-color .3s ease;

  &:hover {
    background-color: $button-hover;
    border-color: $button-hover;
  }

  &:focus {
    background-color: $button-hover;
    border-color: $button-color;
  }
}

.agency__account-reservation-search-container {
  background-image: url(../../img/glass.png);
  background-repeat: no-repeat;
  background-position: 811px;
  cursor: pointer;

}

.agency__account__js-choice-wrap > .choices > .choices__inner,
.booking-date input {
  cursor: pointer;
  border: 2px solid $input-color;
  border-radius: 16px;
  background-color: $input-color;
  transition: background-color .3s ease;

  &:hover {
    background-color: $button-hover;
    border-color: $button-hover;
  }

  &:focus {
    background-color: $button-hover;
    border-color: $button-color;
  }
}

.agency__account-reservation-dropdown-container > .agency__account__js-choice-wrap > .choices > .choices__inner {
  position: relative;
  padding: 24px;
  z-index: 100;
}

.agency__account-reservation-dropdown-container > .agency__account__js-choice-wrap > .is-open.choices > .choices__inner {
  border-color: $button-color;
  background-color: $button-hover;
}

.agency__account-reservation-dropdown-container > .agency__account__js-choice-wrap > .is-focused.choices > .choices__inner {
  border-color: $button-color;
  background-color: $button-hover;
}

.agency__account-reservation-dropdown-container > .agency__account__js-choice-wrap > .choices > .choices__list {
  overflow: scroll;
}

.agency__account-reservation-dropdown-container > .search-tour-form-length > .choices > .choices__inner {
  padding-bottom: 9px;
}

.agency__account-reservation-dropdown-container > .search-tour-form-length > .choices > .choices__inner > .choices__list--single {
  padding-top: 15px;
}

//fixme: remove html code
.agency__account-reservation-search-btn {
  display: none;
}
.agency__account-reservation-search-btn,
.svg-dropdown {
  cursor: pointer;
}

.agency__account-reservation-dropdown {
  position: relative;
  display: flex;
}

.line-betw-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.agency__svg {
  cursor: pointer;
}

.drop-down-container {
  display: flex;
  width: 427px;
  height: 72px;
  padding: 24px;
  justify-content: space-between;
  align-items: center;
  background-color: $input-color;
}

.agency__account-reservation-dropdown {
  display: flex;
}

.agency__account-reservation-dropdown-1 {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.agency__account-reservation-dropdown-container {
  display: flex;
  position: relative;
  background-color: $input-color;
  border-radius: 16px;
}

.agency__account__js-choice-wrap {
  width: 100%;
}

.js-choice-wrap-left {
  border-radius: 16px 0 0 16px;
}

.js-choice-wrap-right {
  border-radius: 0 16px 16px 0;
}

.agency__account-reservation-dropdown-container > .choices {
  width: 100%;
}

.agency__account-reservation-calendar {
  width: 100%;
}

.booking__body {
  .air-datepicker.-bottom-left-.-from-bottom-.-active- {
    width: 426px !important;
  }

  .air-datepicker-body--day-name {
    width: 100% !important;
  }
}

body.booking_body > .air-datepicker-body--day-names > .air-datepicker-body--day-name {
  width: 100%;
}

.agency__account__search-tour-form {
  display: flex;
  width: 100%;
}

.agency__account__date-input {
  width: 100%;
  height: 72px;
}

.label-start-date {
  position: relative;
  left: 24px;
  top: 12px;
  font-family: "Museo Sans Cyrl 300";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  color: #8E8888;
  z-index: 102;
}

.booking-date {
  width: 430px;
  position: relative;
  height: 72px;
  bottom: 23px;
  padding-left: 22px;
  padding-top: 24px;
  border-radius: 16px;
  background-color: #F5F7FA;
  z-index: 101;
  transition: background-color .3s ease;
  border: 2px solid $input-color;
  &:hover {
    background-color: $button-hover;
  }

  &:focus-visible {
    outline: none;
    border: 2px solid $button-color;
    border-radius: 12px;
  }
}

.date-img {
  background-image: url(../../img/calendar.svg);
  background-repeat: no-repeat;
  background-position: 385px;
  cursor: pointer;
}

.agency__account-reservation-dropdown-2 {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.agency-text-grey {
  line-height: 24px;
  width: 355px;
  height: 24px;
  color: $gray;
}

.agency-text-black {
  line-height: 24px;
  width: 355px;
  height: 24px;
  color: $black;
}

.agency__account-reservation-buttons-foot {
  display: flex;
  justify-content: space-between;
}

.agency__account-reservation-buttons-foot-left {
  display: flex;
  gap: 16px;
}

.agency__account-reservation-buttons-foot-right {
  display: flex;
  gap: 24px;
  align-items: center;
}

.agency__account-reservation-buttons-foot-fill {
  width: 134px;
  height: 44px;
}

.agency__account-reservation-buttons-foot-empty {
  width: 156px;
  height: 44px;
}

.agency__account-reservation-cards {
  background-color: $button-hover;
}

.agency__account-reservation-cards-paid {
  background-color: $book-submit;
}



.agency__account-reservation-cards-paid > .agency__account-reservation-cards-footer > .agency__account-reservation-cards-footer-container > .agency__account-reservation-cards-footer-links {
  display: none;
}

.agency__account-reservation-cards-body {
  height: 100%;
  box-shadow: 0 1px 4px 0 $box-shadow-tabs, 0 24px 48px 0 $box-sh-tabs;
}

.agency__account-reservation-cards {
  width: 904px;
  height: 100%;
  border-radius: 24px;
  margin-top: 40px;
}

.agency__account-reservation-cards-head-container {
  display: flex;
  justify-content: space-between;
  width: 904px;
  height: 56px;
  padding: 16px 24px;
  background-color: $white;
  border-bottom: 1px solid var(--border-color, $bottom-line);
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}

.agency__account-reservation-cards-head-number {
  display: flex;
  align-items: center;
  line-height: 24px;
  gap: 4px;
}

.agency__account-reservation-cards-footer-links {
  display: flex;
  gap: 24px;
}

.agency__account-reservation-cards-body-container {
  width: 904px;
  padding: 24px;
  display: grid;
  border-bottom-right-radius: 24px;
  border-bottom-left-radius: 24px;
  background-color: $white;
}

.agency__account-reservation-cards-body-items-descr {
  margin-bottom: 24px;
  line-height: 24px;
}

.agency__account-reservation-cards-body-items {
  display: grid;
  gap: 8px;
  width: 856px;
}

.agency__account-reservation-cards-body-items-name {
  font-family: "Museo Sans Cyrl 500";
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}

.agency__account-reservation-cards-body-date-container {
  width: 424px;
  height: 24px;
  display: flex;
  gap: 32px;
  line-height: 24px;
}

.reservation-card-info {
  display: flex;
  align-items: center;
  gap: 12px;
}

.agency__account-reservation-cards-footer {
  width: 904px;
  height: 100%;
  padding: 16px 23px;
}

.agency__account-reservation-cards-footer-container {
  display: flex;
  align-items: center;
  width: 858px;
  justify-content: space-between;
  line-height: 24px;
}

.agency__account-reservation-cards-footer-state-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.agency__account-reservation-cards-footer-state-topay {
  font-family: 'Museo Sans Cyrl 500';
  font-weight: 600;
  line-height: 24px;
  margin-right: 8px;
}

.agency__account-reservation-cards-footer-state-svg-topay-wrap {
  display: flex;
}

.agency__account-reservation-cards-footer-state-svg {
  margin-right: 8px;
  align-items: center;
  height: 24px;
}

.agency__account-reservation-cards-body-date-second {
  width: 176px;
}

.agency__account-reservation-cards-body-date-first {
  width: 216px;
}

@media screen and (max-width: 768px) {

  .agency__account-container {
    width: 100%;
    height: 100%;
  }

  .agency__account-information {
    display: flex;
    flex-direction: column;
  }

  .agency__account-main-container {
    width: 100%;
    flex-direction: column;
  }

  .agency__account-information-name {
    width: 100%;
  }

  .agency__account-information-line {
    flex-direction: column;
    align-items: start;
  }

  .agency__account-buttons {
    width: 100%;
    height: 100%;
    flex-direction: column;
  }

  .agency__account-button-first,
  .agency__account-button-second {
    width: 100%;
  }

  .agency__account-reservation-container {
    width: 100%;
    height: 100%;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  .agency__account-reservation-container:nth-child(2) {
    gap: 16px;
  }

  .agency__account-reservation-choice {
    width: 245px;
    height: 100%;
    margin-bottom: 24px;
    flex-direction: column;
    gap: 12px;
  }

  .agency__account-reservation-search {
    width: 100%;
    border-radius: 16px;
  }

  .agency__account-reservation-dropdown-container {
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
    background-color: transparent;
  }

  .agency__account__search-tour-form {
    flex-direction: column;
    gap: 16px;
  }

  .agency__account-reservation-choice-name {
    width: 100%;
  }

  .agency__account-reservation-choice-buttons {
    height: 100%;
    flex-direction: column;
    gap: 16px;
    margin-top: 0;
  }

  .booking-date {
    width: 100%;
  }

  .booking__body {
    .air-datepicker.-bottom-left-.-from-bottom-.-active- {
      width: 290px !important;
      z-index: 150 !important;
    }

    .air-datepicker-body--day-name {
      width: 100% !important;
    }
  }

  .agency__account-reservation-buttons-foot {
    flex-direction: column;
    gap: 24px;
    margin-top: 24px;
  }

  .agency__account-reservation-buttons-foot-left {
    flex-direction: column;
  }

  .agency__account-reservation-buttons-foot-fill {
    width: 100%;
  }

  .agency__account-reservation-buttons-foot-empty {
    width: 100%;
  }

  .agency__account-reservation-buttons-foot-right {
    flex-direction: column;
  }

  .agency__account-reservation-cards {
    width: 100%;
    height: 100%;
  }

  .agency__account-reservation-cards-head-container {
    width: 100%;
    height: 100%;
    flex-direction: column;
    gap: 10px;
  }

  .agency__account-reservation-cards-body {
    height: 100%;
  }

  .agency__account-reservation-cards-body-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .agency__account-reservation-cards-body-items {
    max-width: 240px;
    display: flex;
    flex-direction: column;
  }

  .agency__account-reservation-cards-body-date-container {
    width: 100%;
    height: 100%;
    flex-direction: column;
    gap: 16px;
  }

  .agency__account-reservation-cards-footer {
    width: 100%;
    height: 100%;
  }

  .agency__account-reservation-cards-footer-container {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  .agency__account-reservation-cards-footer-state-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
}
